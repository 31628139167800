<template>
    <v-container fluid v-if="$store.state.user.components.includes(34)">
        <v-card>
            <v-stepper v-model="step">
                <v-stepper-header class="pt-0">
                    <!-------------------- STEP 1 ------------------------>
                    <v-stepper-step
                        :complete="step>1"
                        :color="step>1?'green':'primary'"
                        step="1"
                        class="pt-md-3"
                    >
                        <span class="mb-1" v-if="tox.id">New Lab Test</span>
                        <span class="mb-1" v-else>Patient & Billing Type</span>

                        <small v-if="tox.id">{{tox.label}}</small>
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <!-------------------- STEP 2 ------------------------>
                    <v-stepper-step
                        :complete="step>2"
                        :color="step>2?'green':'primary'"
                        step="2"
                        class="pt-md-3"
                    >
                        <span class="mb-1" v-if="tox.patient_id">Patient's Data</span>
                        <span class="mb-1" v-else>New Patient</span>
                        <small v-if="patient">
                            {{patient.first_name}} {{patient.middle_name}}
                            {{patient.last_name}}
                        </small>
                        <small v-if="patient">
                            {{patient.gender}}
                            <b v-if="patient.dob">, DOB:</b>
                            {{patient.dob}}
                        </small>
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <!-------------------- STEP 2 ------------------------>
                    <v-stepper-step
                        :complete="step>3"
                        :color="step>3?'green':'primary'"
                        step="3"
                        class="pt-md-3"
                    >
                        <span class="mb-1">Billing Data</span>
                        <span v-if="tox.insurance_type=='Insurance'">
                            <small v-if="insurances.Primary">
                                <b
                                    v-if="insurances.Primary.InsuranceCompany"
                                >{{insurances.Primary.InsuranceCompany.name}} ({{insurances.Primary.relationship}})</b>
                            </small>
                            <small v-if="insurances.Secondary">
                                <b
                                    v-if="insurances.Secondary.InsuranceCompany"
                                >{{insurances.Secondary.InsuranceCompany.name}} ({{insurances.Secondary.relationship}})</b>
                            </small>
                            <small v-if="insurances.Tertiary">
                                <b
                                    v-if="insurances.Tertiary.InsuranceCompany"
                                >{{insurances.Tertiary.InsuranceCompany.name}} ({{insurances.Tertiary.relationship}})</b>
                            </small>
                        </span>
                        <span v-else>
                            <small>
                                <b>{{tox.insurance_type}}</b>
                            </small>
                        </span>
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <!-------------------- STEP 3 ------------------------>
                    <v-stepper-step
                        :complete="step>4"
                        :color="step>4?'green':'primary'"
                        step="4"
                        class="pt-md-3"
                    >
                        <span class="mb-1">Meds & POC</span>
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <!-------------------- STEP 4 ------------------------>
                    <v-stepper-step
                        :complete="step>5"
                        :color="step>5?'green':'primary'"
                        step="5"
                        class="pt-md-3"
                    >
                        <span class="mb-1">Labs & Tests</span>
                    </v-stepper-step>
                    <v-divider></v-divider>

                    <!-------------------- STEP 5 ------------------------>
                    <v-stepper-step
                        :complete="step>6"
                        :color="step>6?'green':'primary'"
                        step="6"
                        class="pt-md-3"
                    >More Data</v-stepper-step>
                    <v-divider></v-divider>
                </v-stepper-header>

                <v-stepper-items>
                    <!-------------------------- 1 Select Patient---------------------------->
                    <v-stepper-content step="1" class="pa-0">
                        <step0
                            :patient_id.sync="tox.patient_id"
                            :physician_id.sync="tox.physician_id"
                            :billing_type.sync="tox.insurance_type"
                            :facility_id.sync="tox.facility_id"
                            :physician_name="tox.physician_name"
                            :patient_name="tox.patient_name"
                            :step="stepper"
                        ></step0>
                    </v-stepper-content>
                    <!-------------------------- 1 Patient Details ---------------------------->
                    <v-stepper-content step="2" class="pa-0">
                        <step1
                            :patient_id.sync="tox.patient_id"
                            :billing_type="tox.insurance_type"
                            v-model="patient"
                            :step="stepper"
                        ></step1>
                    </v-stepper-content>
                    <!---------------------------- 2 INSURANCES ------------------------------->
                    <v-stepper-content step="3" class="pa-0">
                        <step2 v-model="insurances" :patient="patient" :step="stepper" :tox="tox"></step2>
                    </v-stepper-content>
                    <!--------------------------- 3 Meds and POC---------------------------------->
                    <v-stepper-content step="4" class="pa-0">
                        <step3
                            :step="stepper"
                            :tox_id="tox.id"
                            :patient_id="patient.id"
                            :pocs.sync="pocs"
                            :meds.sync="meds"
                        ></step3>
                    </v-stepper-content>
                    <!--------------------------- 4 Tests and CPT -------------------------------->
                    <v-stepper-content step="5" class="pa-0">
                        <step4
                            v-model="tests_cpts"
                            :insurances="insurances"
                            :step="stepper"
                            :ordered_codes="ordered_codes"
                            :selected_labs="selected_labs"
                            :billing_type="tox.insurance_type"
                        ></step4>
                    </v-stepper-content>
                    <!--------------------------  5 Signature and Docs ----------------------------->
                    <v-stepper-content step="6" class="pa-0">
                        <step5
                            :tests_cpts="tests_cpts"
                            :step="stepper"
                            v-model="collectorAndMap"
                            :selected_diagnosis="selected_diagnosis"
                            :cpt_dg_map="cpt_dg_map"
                            :tempOk="tox.temperature_ok"
                            :collector="{id:tox.collector_id,name:tox.collector_name}"
                            :collected="tox.collected"
                            :tox_id="tox.id"
                            :facility_id="tox.facility_id"
                            :physician_id="tox.physician_id"
                        ></step5>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
        <v-dialog v-model="loading" persistent hide-overlay max-width="300" width="90vh">
            <v-card color="primary darken-3" dark>
                <v-card-title>
                    {{loading_label}}
                    <v-spacer></v-spacer>
                    <v-icon>mdi-content-save</v-icon>
                </v-card-title>
                <v-card-text>
                    Please wait a moment
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
//import autocomplete from "../components/AutoComplete.vue";
//import dpinput from '../components/DatePickerInput.vue';

import step0 from "./LabtestNew/stepSelectPatient";
import step1 from "./LabtestNew/stepPatient.vue";
import step2 from "./LabtestNew/stepInsurance";
import step3 from "./LabtestNew/stepMedsPoc.vue";
import step4 from "./LabtestNew/stepTests.vue";
import step5 from "./LabtestNew/stepCptProviders.vue";
import gql from "graphql-tag";

export default {
    components: {
        step0,
        step1,
        step2,
        step3,
        step4,
        step5,
    },
    data() {
        return {
            step: 1,
            maxSteps: 6,
            loading: false,
            loading_label: "",

            tox: {
                id: undefined,
                status: null,
                created_by: this.$store.state.user.payload.id,
                facility_id: null,
                physician_id: null,
                insurance_type: null,
                patient_id: null,
            },
            patient: {},
            insurances: {},
            tests_cpts: {},
            collectorAndMap: {
                dt: {
                    collector: null,
                    temp: null,
                    collected_date: null,
                    collected_time: null,
                    physician_signature: null,
                    patient_signature: null,
                },
                map: {},
            },
            pocs: [],
            meds: [],

            ordered_codes: [],
            selected_labs: [],
            selected_diagnosis: [],
            cpt_codes_map: {},
            dg_codes_map: {},
            cpt_dg_map: {},
        };
    },
    computed: {
        stepper() {
            return {
                step: this.step,
                up: this.stepUp,
                down: this.stepDown,
                save: this.save,
            };
        },
    },
    watch: {
        step() {
            if (!this.tox.id) this.saveTox();
        },
        patient: {
            handler(n) {
                if (n.id && this.$route.params.patient_id) {
                    this.tox.patient_name =
                        n.first_name +
                        (n.middle_name ? n.middle_name + " " : "") +
                        " " +
                        n.last_name;
                }
            },
            deep: true,
        },
    },
    methods: {
        save() {
            this.tox.status = "Saved";
            this.saveCodes();
        },
        stepUp() {
            if (this.step < this.maxSteps) this.step++;
            else {
                this.tox.status = "Sending";
                this.saveCodes();
            }
        },
        stepDown() {
            if (this.step > 1) this.step--;

            //if (this.tox.insurance_type != "Insurance" && this.step == 3)
            //this.step--;
        },
        saveTox() {
            this.loading_label = "Creating eReq ...";
            this.loading = true;

            let tox = JSON.parse(JSON.stringify(this.tox));

            if (!tox.status) tox.status = "Saved";
            tox.__typename = undefined;
            tox.label = undefined;
            tox.patient_name = undefined;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($inputdata: [ToxSave]!) {
                            ToxsSave(data: $inputdata) {
                                id
                                label
                                status
                            }
                        }
                    `,
                    variables: {
                        inputdata: [tox],
                    },
                })
                .then((res) => {
                    this.tox = { ...this.tox, ...res.data.ToxsSave[0] };
                })
                .finally(() => {
                    this.loading = false;
                })
                .catch(() => {
                    alert("Something went wrong while saving tox!");
                });
        },
        saveCodes() {
            this.loading_label = "Saving Data ...";
            this.loading = true;

            // Cleaning unnecessary parameters
            if (this.tests_cpts.tests instanceof Array) {
                this.tests_cpts.tests.forEach((v) => {
                    if (v.tests instanceof Array) {
                        v.tests.forEach((t) => {
                            if (t.Type instanceof Object) {
                                t.Type.__typename = undefined;
                            }
                            if (t.Container instanceof Object) {
                                t.Container.__typename = undefined;
                            }
                            t.__typename = undefined;
                        });
                    }
                    v.__typename = undefined;
                    v.Settings = undefined;
                });
            }
            if (this.tests_cpts.cpts instanceof Array) {
                this.tests_cpts.cpts.forEach((c) => {
                    c.id = undefined;
                    c.facility_id = undefined;
                    c.payer_group_id = undefined;
                    c.priority = undefined;
                    c.type = undefined;
                    c.description = undefined;
                    c.price = undefined;
                    c.count_from = undefined;
                    c.count_to = undefined;
                    c.active_from = undefined;
                    c.active_to = undefined;
                    c.__typename = undefined;
                    c.tIDs = undefined;
                    c.gIDs = undefined;
                    c.valid = undefined;

                    if (c.diagnosis["1"] instanceof Object) {
                        c.diagnosis["1"].name = undefined;
                        c.diagnosis["1"].__typename = undefined;
                    }
                    if (c.diagnosis["2"] instanceof Object) {
                        c.diagnosis["2"].name = undefined;
                        c.diagnosis["2"].__typename = undefined;
                    }
                    if (c.diagnosis["3"] instanceof Object) {
                        c.diagnosis["3"].name = undefined;
                        c.diagnosis["3"].__typename = undefined;
                    }
                    if (c.diagnosis["4"] instanceof Object) {
                        c.diagnosis["4"].name = undefined;
                        c.diagnosis["4"].__typename = undefined;
                    }
                });
            }
            if (this.pocs instanceof Array) {
                this.pocs.forEach((poc) => {
                    poc.__typename = undefined;
                    poc.tox_poc_id = undefined;
                });
            }
            if (this.meds instanceof Array) {
                this.meds.forEach((med) => {
                    med.Medication = undefined;
                    med.__typename = undefined;
                    med.state = undefined;
                    med.tmeds = undefined;
                    med.pmeds = undefined;
                    med.match = undefined;
                });
            }
            if (this.collectorAndMap.map.diagnosis instanceof Array) {
                this.collectorAndMap.map.diagnosis.forEach((v) => {
                    v.name = undefined;
                    v.__typename = undefined;
                });
            }
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation (
                            $tox: ToxSaveToxArg!
                            $tests: [ToxSaveCodesArg]
                            $cptsDgMap: ToxSaveCPTsDgArg
                            $meds: [ToxSaveMedicationsArg]
                            $pocs: [ToxSavePocsArg]
                            $insurances: [ToxSaveInsurancesArg]
                            $patient: ToxSavePatientArg
                            $guarantor: ToxSaveGuarantorArg
                        ) {
                            ToxSaveCodes(
                                tox: $tox
                                tests: $tests
                                cptsDgMap: $cptsDgMap
                                medications: $meds
                                pocs: $pocs
                                insurances: $insurances
                                patient: $patient
                                guarantor: $guarantor
                            ) {
                                id
                            }
                        }
                    `,
                    variables: {
                        tox: {
                            ...this.tox,
                            __typename: undefined,
                            created_by: undefined,
                            physician_name: undefined,
                            patient_name: undefined,
                            collector_name: undefined,
                            collector_id: this.collectorAndMap.dt.collector,
                            collected:
                                (this.collectorAndMap.dt.collected_date || "") +
                                " " +
                                (this.collectorAndMap.dt.collected_time || ""),
                            temperature_ok: this.collectorAndMap.dt.temp,
                        },
                        tests: this.tests_cpts.tests,
                        cptsDgMap: this.collectorAndMap.map,
                        meds: this.meds,
                        pocs: this.pocs,
                        patient: {
                            ...this.patient,
                            ids: undefined,
                            pictures: undefined,
                            __typename: undefined,
                        },
                        guarantor: this.guarantor,
                        insurances: [
                            {
                                ...this.insurances.Primary,
                                InsuranceCompany: undefined,
                                __typename: undefined,
                            },
                            {
                                ...this.insurances.Secondary,
                                InsuranceCompany: undefined,
                                __typename: undefined,
                            },
                            {
                                ...this.insurances.Tertiary,
                                InsuranceCompany: undefined,
                                __typename: undefined,
                            },
                        ],
                    },
                })
                .then(() => {
                    this.$router.push("/labtestList");
                })
                .catch(() => {
                    alert("Something went Wrong!");
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadTox(id) {
            this.loading_label = "Loading Data ...";
            this.loading = true;

            this.$apollo
                .query({
                    query: gql` {
                    Tox(id:"${id}") {
                        id
                        label
                        patient_id
                        patient_name
                        physician_id
                        physician_name
                        insurance_type
                        status
                        collector_id
                        collector_name
                        collected
                        temperature_ok
                        facility_id
                    }
                    ToxOrderedCodes(where:[{tox_id:{eq:"${id}"}}, {deleted:{is:null}}]) {
                        id
                        tox_id
                        facility_id
                        code_id
                        code
                        code_label
                        code_abrv
                    }
                    ToxDiagnosiss(where:[{tox_id:{eq:"${id}"}}, {deleted:{is:null}}]) {
                        id
                        code_id
                        icd10_raw
                        icd10_code
                        icd10_description
                    }
                    ToxDiagnosisMaps(where:[{tox_id:{eq:"${id}"}}, {deleted:{is:null}}]) {
                        cpt_id
                        diagnosis_id
                        priority
                    }
                    ToxCptCodes(where:[{tox_id:{eq:"${id}"}}, {deleted:{is:null}}]) {
                        id
                        code
                    }
                }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.Tox instanceof Object) {
                        this.tox = res.data.Tox;
                    }
                    if (res.data.ToxOrderedCodes instanceof Array) {
                        this.ordered_codes = [];
                        this.selected_labs = [];
                        res.data.ToxOrderedCodes.forEach((v) => {
                            if (this.ordered_codes.indexOf(v.code_id) < 0)
                                this.ordered_codes.push(v.code_id);
                            if (this.selected_labs.indexOf(v.facility_id) < 0)
                                this.selected_labs.push(v.facility_id);
                        });
                    }
                    if (res.data.ToxDiagnosiss instanceof Array) {
                        this.selected_diagnosis = [];
                        res.data.ToxDiagnosiss.forEach((v) => {
                            this.dg_codes_map[v.id] = v.code_id;
                            this.selected_diagnosis.push({
                                ...v,
                                id: v.code_id,
                                code_id: undefined,
                                name: v.icd10_code + " " + v.icd10_description,
                                __typename: undefined,
                            });
                        });
                    }
                    if (res.data.ToxCptCodes instanceof Array) {
                        res.data.ToxCptCodes.forEach((v) => {
                            this.cpt_codes_map[v.id] = v.code;
                        });
                    }
                    if (res.data.ToxDiagnosisMaps instanceof Array) {
                        let temp = {};
                        res.data.ToxDiagnosisMaps.forEach((v) => {
                            if (
                                this.cpt_codes_map[v.cpt_id] &&
                                this.dg_codes_map[v.diagnosis_id]
                            ) {
                                if (
                                    temp[this.cpt_codes_map[v.cpt_id]] ==
                                    undefined
                                )
                                    temp[this.cpt_codes_map[v.cpt_id]] = [];

                                temp[this.cpt_codes_map[v.cpt_id]][
                                    v.priority - 1
                                ] = this.dg_codes_map[v.diagnosis_id];
                            }
                        });
                        this.cpt_dg_map = temp;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    beforeDestroy() {
        //alert("Do you want to save?")
    },
    mounted() {
        if (this.$route.params.ereq_id) {
            this.loadTox(this.$route.params.ereq_id);
        }
        if (this.$route.params.patient_id) {
            this.tox.patient_id = this.$route.params.patient_id;
        }
    },
};
</script>
<style scoped>
#scanner {
    width: 90%;
    height: 300px;
    background-color: lightgray;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    position: relative;
}

#idcard {
    width: 80%;
    height: 80%;
    margin: 3% auto auto 3%;
    background-image: url("../assets/id.png");
    background-repeat: no-repeat;
    background-size: contain;
}

#scanned {
    background-color: lightgray;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 999;
}
</style>