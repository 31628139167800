<template>
    <v-container fluid class="pa-0">
        <v-card flat>
            <v-card-title>
                <v-autocomplete
                    item-value="id"
                    item-text="name"
                    label="Add Diagnosis"
                    hide-details="auto"
                    outlined
                    dense
                    placeholder=" "
                    return-object
                    no-filter
                    v-model="dg"
                    :search-input.sync="search"
                    :loading="loading"
                    :items="items"
                    :disabled="!collected"
                >
                    <template v-slot:append-outer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-if="!collected" class="red--text" v-on="on">mdi-alert</v-icon>
                            </template>
                            <span>Please select collected date first!</span>
                        </v-tooltip>
                    </template>
                </v-autocomplete>
            </v-card-title>

            <v-simple-table dense>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>
                            <b>Diagnosis</b>
                        </th>

                        <th v-for="(cpt,cptk) in cpts" :key="cptk" style="white-space: nowrap;">
                            <b>{{cpt}}</b>
                            <v-btn x-small icon @click="clearColumn(cpt)">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </th>

                        <th>
                            <v-btn x-small icon @click="checkAll()">
                                <v-icon>mdi-check-box-multiple-outline</v-icon>
                            </v-btn>
                            <v-btn x-small icon @click="clearAll()">
                                <v-icon>mdi-close-box-multiple-outline</v-icon>
                            </v-btn>
                            <v-btn x-small icon @click="deleteAll()">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dg,dgk) in dgs" :key="dgk">
                        <td>{{dgk+1}}</td>
                        <td>{{dg.name}}</td>

                        <th v-for="(cpt,cptk) in cpts" :key="cptk" class="text-center">
                            <v-chip
                                outlined
                                small
                                v-if="map[cpt] && map[cpt].indexOf(dg.id)>=0"
                                @click="map[cpt].splice(map[cpt].indexOf(dg.id),1)"
                            >
                                <v-icon color="green">mdi-check</v-icon>
                                {{map[cpt].indexOf(dg.id)+1}}
                            </v-chip>
                            <input
                                type="checkbox"
                                v-else-if="!map[cpt] || map[cpt].length<4"
                                @change="map[cpt].push(dg.id)"
                            />
                        </th>

                        <td>
                            <v-btn x-small icon @click="moveUp(dgk)">
                                <v-icon>mdi-arrow-up-bold</v-icon>
                            </v-btn>
                            <v-btn x-small icon @click="moveDown(dgk)">
                                <v-icon>mdi-arrow-down-bold</v-icon>
                            </v-btn>
                            <v-btn x-small icon @click="deleteRow(dgk)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
        <v-snackbar right top color="amber darken-3" v-model="notAdded" timeout="3000">
            <v-row>
                <v-col cols="auto">
                    <v-icon>mdi-alert-outline</v-icon>
                </v-col>
                <v-col cols="auto">
                    <b>Diagnosis is duplicated!</b>
                    <br />Please select another one!
                </v-col>
            </v-row>
        </v-snackbar>
    </v-container>
</template>
<script>
//import autocomplete from "../../components/AutoComplete";
import gql from "graphql-tag";
export default {
    props: {
        value: Object,
        cpt_codes: Array,
        selected_diagnosis: Array,
        cpt_dg_map: Object,
        collected: String,
    },
    data() {
        return {
            dg: null,
            dgs: [],
            cpts: [],
            notAdded: false,
            map: {},
            search: null,
            loading: false,
            items: [],
        };
    },
    computed: {
        cpts_result() {
            return this.cpts.map((cpt) => {
                return { code: cpt, map: this.map[cpt] };
            });
        },
    },
    watch: {
        dg(n) {
            if (n) {
                if (this.dgs.filter((v) => v.id == n.id).length < 1) {
                    this.dgs.push(n);
                    this.cpts.forEach((cpt) => {
                        if (!(this.map[cpt] instanceof Array))
                            this.$set(this.map, cpt, []);
                        if (this.map[cpt].length < 4) this.map[cpt].push(n.id);
                    });
                } else this.notAdded = true;

                setTimeout(() => {
                    this.dg = undefined;
                }, 10);
            }
        },
        dgs: {
            handler() {
                this.$emit("input", {
                    diagnosis: this.dgs,
                    cpts: this.cpts_result,
                });
            },
            deep: true,
        },
        map: {
            handler() {
                this.$emit("input", {
                    diagnosis: this.dgs,
                    cpts: this.cpts_result,
                });
            },
            deep: true,
        },
        cpt_codes: {
            handler(n) {
                let ns = JSON.stringify(n);
                let cs = JSON.stringify(this.cpts);
                if (ns != cs && n instanceof Array) {
                    this.cpts = JSON.parse(JSON.stringify(n));
                    this.cpts.forEach((cpt) => {
                        if (!(this.map[cpt] instanceof Array)) {
                            this.$set(this.map, cpt, []);
                        }
                    });
                    Object.keys(this.map).forEach((k) => {
                        if (this.cpts.indexOf(k) < 0) this.map[k] = undefined;
                    });
                    this.$emit("input", {
                        diagnosis: this.dgs,
                        cpts: this.cpts_result,
                    });
                }
            },
            immediate: true,
        },
        search(n) {
            if (
                n &&
                n.length > 2 &&
                this.items.filter((v) => v.name == n).length < 1
            ) {
                this.findItems(n);
            }
        },
        selected_diagnosis(n) {
            if (n instanceof Array && n != this.dgs) {
                this.dgs = n;
            }
        },
        cpt_dg_map(n) {
            if (n instanceof Object && n != this.map) {
                this.map = JSON.parse(JSON.stringify(n));
            }
        },
    },
    methods: {
        findItems(search) {
            this.loading = true;
            this.$apollo
                .query({
                    query: gql`{
                        Diagnosiss(where:[{name:{like:"%${search}%"}}, {deleted:{is:null}}, {OR:[{inactive:{is:null}}, {inactive: {more:"${this.collected}"}}]}], options:{page:1,itemsPerPage:20}) {
                            id
                            icd10_raw
                            icd10_code
                            icd10_description
                            name
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.Diagnosiss instanceof Array) {
                        this.items = res.data.Diagnosiss;
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        moveUp(k) {
            if (k > 0) {
                let elm = this.dgs[k - 1];
                this.dgs.splice(k - 1, 1);
                this.dgs.splice(k, 0, elm);
            }
        },
        moveDown(k) {
            if (k < this.dgs.length - 1) {
                let elm = this.dgs[k + 1];
                this.dgs.splice(k + 1, 1);
                this.dgs.splice(k, 0, elm);
            }
        },
        deleteRow(k) {
            let dg = this.dgs[k];
            this.dgs.splice(k, 1);
            this.cpts.forEach((cpt) => {
                let pos = this.map[cpt].indexOf(dg.id);
                if (pos >= 0) this.map[cpt].splice(pos, 1);
            });
        },
        clearColumn(cpt) {
            this.map[cpt] = [];
        },
        checkAll() {
            this.dgs.forEach((dg) => {
                this.cpts.forEach((cpt) => {
                    if (
                        this.map[cpt].length < 4 &&
                        this.map[cpt].indexOf(dg.id) < 0
                    ) {
                        this.map[cpt].push(dg.id);
                    }
                });
            });
        },
        clearAll() {
            Object.keys(this.map).forEach((k) => {
                this.$set(this.map, k, []);
            });
        },
        deleteAll() {
            Object.keys(this.map).forEach((k) => {
                this.$set(this.map, k, []);
            });
            this.dgs = [];
        },
    },
    components: {
        //autocomplete,
    },
};
</script>