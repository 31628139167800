<template>
    <v-container class="py-0" fluid>
        <v-container class="pa-0" fluid>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card height="100%" :loading="loading">
                        <v-card-title>
                            Patient Details
                            <!--<v-spacer></v-spacer>
                            <v-btn
                                v-if="patient_editable"
                                small
                                v-on:click="savePatient()"
                                color="warning"
                                :disabled="!validated"
                            >
                                <v-icon>mdi-content-save</v-icon>Save Patient
                            </v-btn>
                            <v-btn v-else small v-on:click="patient_editable=true">
                                <v-icon>mdi-account-edit</v-icon>Edit
                            </v-btn>-->
                        </v-card-title>

                        <v-card-text>
                            <v-form v-model="validated">
                                <v-row>
                                    <v-col cols="12" sm="6" align="center">
                                        <v-avatar
                                            class="profile"
                                            color="grey"
                                            size="174"
                                            tile
                                            v-on:click="uploadPicture('picture')"
                                        >
                                            <v-row
                                                v-if="uploading_picture"
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="grey lighten-5"
                                                ></v-progress-circular>
                                            </v-row>
                                            <v-img
                                                v-else-if="patient.pictures && patient.pictures[0]"
                                                :src="'https://lab-synapse.com/gql/storage?id='+patient.pictures[0].id"
                                                class="elevation-10"
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="black lighten-5"
                                                            size="50"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <v-img
                                                v-else
                                                src="../../assets/noPicture.jpg"
                                                class="elevation-10"
                                            ></v-img>
                                        </v-avatar>
                                        <br />
                                        <small>Click to upload an image</small>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="First Name"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.first_name"
                                            :class="rules.first_name.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.first_name"
                                        ></v-text-field>
                                        <v-text-field
                                            label="Middle Name"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.middle_name"
                                            :class="rules.middle_name.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.middle_name"
                                        ></v-text-field>
                                        <v-text-field
                                            label="Last Name"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.last_name"
                                            :class="rules.last_name.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.last_name"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" sm="4">
                                        <dob
                                            label="Date of Birth"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.dob"
                                            :mandatory="rules.dob.length>0"
                                            :disabled="!patient_editable"
                                            v-model="patient.dob"
                                        ></dob>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            label="Gender"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.gender"
                                            :class="rules.gender.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.gender"
                                            :items="$store.state.genders"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field
                                            v-mask="['###-##-####']"
                                            label="SSN"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.ssn"
                                            :class="rules.ssn.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.ssn"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Address1"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.address1"
                                            :class="rules.address1.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.address1"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="Address2"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.address2"
                                            :class="rules.address2.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.address2"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-combobox
                                            label="ZIP Code"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.zip"
                                            :class="rules.zip.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            :items="zItems"
                                            :loading="zLoading"
                                            :search-input.sync="zSearch"
                                            item-value="zip"
                                            item-text="zip"
                                            v-model="patient.zip"
                                            @change="czChange"
                                            ref="patientZip"
                                        >
                                            <template v-slot:item="{item}">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <b>{{item.zip}}</b>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        City:&nbsp;
                                                        <b>{{item.city}}</b>
                                                        State:&nbsp;
                                                        <b>{{item.state}}</b>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-combobox>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-combobox
                                            label="City"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.city"
                                            :class="rules.city.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            :items="cItems"
                                            :loading="cLoading"
                                            :search-input.sync="cSearch"
                                            item-value="id"
                                            item-text="city"
                                            v-model="patient.city"
                                            @change="czChange"
                                            no-filter
                                        >
                                            <template v-slot:item="{item}">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <b>{{item.city}}</b>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        State:&nbsp;
                                                        <b>{{item.state}}</b>
                                                        Zip:&nbsp;
                                                        <b>{{item.zip}}</b>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-select
                                            label="State"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.state"
                                            :class="rules.state.length  ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.state"
                                            :items="$store.state.usStatesAbrv"
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            label="E-mail"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.email"
                                            :class="rules.email.length ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.email"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                            v-mask="['(###) ###-####','(###) ###-####/###']"
                                            label="Phone"
                                            placeholder=" "
                                            dense
                                            outlined
                                            :rules="rules.phone"
                                            :class="rules.phone.length ? 'required' : null"
                                            :disabled="!patient_editable"
                                            v-model="patient.phone"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card height="100%">
                        <v-tabs>
                            <v-tab>Attached Ids</v-tab>

                            <v-tab-item>
                                <v-row>
                                    <v-col
                                        class="text-center"
                                        v-if="current_file"
                                    >Created: {{current_file.fileDate}}</v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-carousel
                                            v-if="patient.ids"
                                            v-model="current_file"
                                            :height="400"
                                        >
                                            <v-carousel-item
                                                v-for="file in patient.ids"
                                                :key="file.id"
                                                :value="file"
                                            >
                                                <v-img
                                                    :src="'https://lab-synapse.com/gql/storage/?id='+file.id"
                                                    max-height="400"
                                                    contain
                                                >
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <v-progress-circular
                                                                indeterminate
                                                                color="black lighten-5"
                                                                size="50"
                                                            ></v-progress-circular>
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                            </v-carousel-item>
                                        </v-carousel>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col align="center">
                                        <v-btn @click="captureDialog = true" class="mr-2">Scan ID</v-btn>
                                        <v-btn v-on:click="uploadPicture('id')">Upload ID</v-btn>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="space-around">
                <v-col cols="4" align="center">
                    <v-btn rounded v-on:click="step.down()">
                        <v-icon>mdi-arrow-left-bold</v-icon>BACK
                    </v-btn>
                </v-col>
                <v-col cols="4" align="center">
                    <v-btn rounded v-on:click="step.save()">
                        <v-icon>mdi-content-save</v-icon>Save & Close
                    </v-btn>
                </v-col>
                <v-col cols="4" align="center">
                    <v-btn
                        rounded
                        v-on:click="next()"
                        :disabled="!validated || loading"
                        :color="validated && !loading?'green':null"
                    >
                        NEXT
                        <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar
            v-model="snackBar"
            top
            right
            :timeout="2000"
            :color="snackBarColor"
        >{{snackBarText}}</v-snackbar>
        <v-dialog
            v-model="captureDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <ImageCapture
                :ocr="false"
                cardType="Id"
                @captureComplete="onCaptureComplete"
                @close-dialog="captureDialog=false"
            />
        </v-dialog>
        <input type="file" name="file" style="visibility: hidden; display:none" ref="fileInput" />
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import dob from "../../components/DOB.vue";
import { mask } from "vue-the-mask";
import ImageCapture from "../../components/ImageCapture.vue";
export default {
    components: {
        dob,
        ImageCapture,
    },
    directives: {
        mask,
    },
    props: ["value", "step", "patient_id", "billing_type"],
    watch: {
        value(n) {
            this.patient = n;
        },
        patient_id: {
            handler(n) {
                if (n) {
                    this.loadPatient(n);
                } else {
                    this.patient = {};
                    this.patient_compare = {};
                }
            },
            immediate: true,
        },
        patient(n) {
            this.$emit("input", n);
            if (n.id) this.$emit("update:patient_id", n.id);
        },
        cSearch(n) {
            if (n && n.length > 1) {
                let found = this.cItems.filter((v) => v.city == n);
                if (found.length <= 0) this.findCity(n);
            }
        },
        zSearch(n) {
            if (n && n.length > 1) {
                let found = this.zItems.filter((v) => v.zip == n);
                if (found.length <= 0) this.findZip(n);
                if (found.length == 1) {
                    this.czChange(found[0]);
                    setTimeout(() => {
                        this.$refs.patientZip.selectItem(found[0].zip);
                    }, 800);
                }
            }
        },
    },
    data() {
        return {
            patient: {},
            patient_compare: {},
            //patientID: null,
            //searchPatient: null,
            uploading_picture: false,

            patient_editable: true,
            patient_urlLoading: false,
            patient_url: null,
            patient_ids: [],
            patient_idsLoading: false,

            loading: false,

            snackBar: false,
            snackBarText: "DEMO",
            snackBarColor: "success",

            current_file: null,
            validated: false,
            stepAllowed: true,

            fields: {
                first_name: null,
                middle_name: null,
                last_name: null,
                dob: null,
                gender: null,
                ssn: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip: null,
                email: null,
                phone: null,
            },

            cSearch: null,
            cLoading: false,
            cItems: [],
            zSearch: null,
            zLoading: false,
            zItems: [],

            captureDialog: false,
        };
    },
    computed: {
        hasDiff() {
            //console.log(JSON.stringify(this.patient),JSON.stringify(this.patient_compare));
            return (
                JSON.stringify(this.patient) !=
                JSON.stringify(this.patient_compare)
            );
        },
        rules() {
            let rules = {};
            if (this.billing_type == "Insurance" || this.billing_type == "Self Pay") {
                Object.keys(this.fields).forEach((k) => {
                    if (
                        k != "middle_name" &&
                        k != "email" &&
                        k != "ssn" &&
                        k != "address2"
                    ) {
                        rules[k] = [(v) => !!v || "Required Field"];
                        if (k == "phone")
                            rules[k].push(
                                (v) => v?.length >= 14 || "Invalid Phone Number"
                            );
                    } else {
                        rules[k] = [];
                    }
                });
            } else if (this.billing_type == "Client Pay" || this.billing_type == "Pre-Paid") {
                Object.keys(this.fields).forEach((k) => {
                    if (
                        k == "first_name" ||
                        k == "last_name" ||
                        k == "dob" ||
                        k == "gender"
                    ) {
                        rules[k] = [(v) => !!v || "Required Field"];
                    } else {
                        rules[k] = [];
                    }
                });
            } else {
                Object.keys(this.fields).forEach((k) => {
                    rules[k] = [];
                });
            }
            return rules;
        },
    },
    methods: {
        next() {
            if (this.validated) {
                if (this.hasDiff) this.savePatient();
                this.step.up();
            }
        },
        loadPatient(pID) {
            this.loading = true;
            this.$apollo
                .query({
                    query: gql`
                        query ($id: ID!) {
                            Patient(id: $id) {
                                id
                                first_name
                                middle_name
                                last_name
                                gender
                                dob
                                address1
                                address2
                                city
                                state
                                zip
                                email
                                phone
                                ssn
                                ids: Files(
                                    where: [
                                        { type: { eq: "id" } }
                                        { status: { eq: "OK" } }
                                        { deleted: { is: null } }
                                    ]
                                ) {
                                    id
                                    fileDate: date
                                }
                                pictures: Files(
                                    where: [
                                        { type: { eq: "picture" } }
                                        { status: { eq: "OK" } }
                                        { deleted: { is: null } }
                                    ]
                                    options: {
                                        page: 1
                                        itemsPerPage: 1
                                        sortBy: [id]
                                        sortDesc: [true]
                                    }
                                ) {
                                    id
                                    fileDate: date
                                }
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        id: pID,
                    },
                })
                .then((res) => {
                    if (res.data.Patient instanceof Object) {
                        this.patient = res.data.Patient;
                        this.patient_compare = JSON.parse(
                            JSON.stringify(res.data.Patient)
                        );
                        //console.log(res.data.Patient);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        savePatient() {
            let patient = this._copyScalar(this.patient);
            //this.patient_editable = false;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($inputdata: [PatientSave]!) {
                            PatientsSave(data: $inputdata) {
                                id
                                first_name
                                middle_name
                                last_name
                                gender
                                dob
                                address1
                                address2
                                city
                                state
                                zip
                                email
                                phone
                                ssn
                                ids: Files(
                                    where: [
                                        { type: { eq: "id" } }
                                        { deleted: { is: null } }
                                    ]
                                ) {
                                    id
                                    fileDate: date
                                }
                                pictures: Files(
                                    where: [
                                        { type: { eq: "picture" } }
                                        { deleted: { is: null } }
                                    ]
                                    options: {
                                        page: 1
                                        itemsPerPage: 1
                                        sortBy: [id]
                                        sortDesc: [true]
                                    }
                                ) {
                                    id
                                    fileDate: date
                                }
                            }
                        }
                    `,
                    variables: {
                        inputdata: [patient],
                    },
                })
                .then((res) => {
                    this.snackBarColor = "success";
                    this.snackBarText = "SUCCESS: Patient Updated !";
                    this.snackBar = true;
                    this.patient = res.data.PatientsSave[0];
                })
                .catch(() => {
                    this.snackBarColor = "error";
                    this.snackBarText = "ERROR: Patient Not Updated !";
                    this.snackBar = true;
                    this.$apollo.queries.patient.refetch();
                });
        },
        uploadPicture(type) {
            if (this.patient.id === undefined) this.savePatient();
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.setAttribute("img-type", type);
            this.$refs.fileInput.accept = ".gif,.jpg,.jpeg,.bmp,.svg,.png";
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener(
                "change",
                this._uploadFile
            );
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            if (this.patient.id === undefined) {
                alert("Please wait until the patient is saved!");
                return;
            }

            let type = e.target.getAttribute("img-type");
            if (type == "picture") this.uploading_picture = true;

            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("file", file, file.filename);
            this.axios
                .post(
                    "https://lab-synapse.com/gql/storage/?type=" +
                        type +
                        "&ownerType=patients&ownerID=" +
                        this.patient.id,
                    formData
                )
                .then((res) => {
                    if (res.data.status == "uploaded") {
                        if (type == "picture")
                            this.patient.pictures = [{ id: res.data.fileID }];
                        if (type == "id") {
                            let obj = { id: res.data.fileID, fileDate: "Now" };
                            this.patient.ids.push(obj);
                            this.current_file = obj;
                        }
                        //if (type == "ins") this.getInsDocs();
                    } else alert("Uploading failed!");
                })
                .catch(() => {
                    alert("Uploading failed !!!");
                })
                .finally(() => {
                    if (type == "picture") this.uploading_picture = false;

                    e.target.value = null;
                });
        },
        _copyScalar(obj) {
            let res = {};
            Object.keys(obj).forEach((k) => {
                if (!(obj[k] instanceof Object) && k.substring(0, 2) != "__")
                    res[k] = obj[k];
            });
            return res;
        },

        findCity(val) {
            this.cLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                        ZipCodes(where:{city:{like:"${val}%"}},options:{page:1,itemsPerPage:20}) {
                            id
                            zip
                            city
                            state
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ZipCodes instanceof Array) {
                        this.cItems = res.data.ZipCodes;
                    }
                })
                .finally(() => {
                    this.cLoading = false;
                });
        },
        findZip(val) {
            this.zLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                        ZipCodes(where:{zip:{like:"${val}%"}},options:{page:1,itemsPerPage:20}) {
                            id
                            zip
                            city
                            state
                        }
                    }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ZipCodes instanceof Array) {
                        this.zItems = res.data.ZipCodes;
                        let found = this.zItems.filter(
                            (v) => v.zip == this.zSearch
                        );
                        if (found.length == 1) {
                            this.czChange(found[0]);
                            setTimeout(() => {
                                this.$refs.patientZip.selectItem(found[0].zip);
                            }, 800);
                        }
                    }
                })
                .finally(() => {
                    this.zLoading = false;
                });
        },
        czChange(v) {
            if (v instanceof Object) {
                if (!this.zItems.some((item) => item.zip == v.zip))
                    this.zItems.push(v);
                if (!this.cItems.some((item) => item.zip == v.zip))
                    this.cItems.push(v);

                this.$set(this.patient, "city", v.city);
                this.$set(this.patient, "state", v.state);
                this.$set(this.patient, "zip", v.zip);
            }
        },
        onCaptureComplete(cardFront, cardBack) {
            let formData = null;

            if (cardFront) {
                formData = new FormData();
                formData.append(
                    "file",
                    this.b64ToBlob(cardFront),
                    "card-0.jpg"
                );

                this.axios
                    .post(
                        "https://lab-synapse.com/gql/storage/?type=id&ownerType=patients&ownerID=" +
                            this.patient.id,
                        formData
                    )
                    .then((res) => {
                        if (res.data.status == "uploaded") {
                            let obj = { id: res.data.fileID, fileDate: "Now" };
                            this.patient.ids.push(obj);
                            this.current_file = obj;
                        } else alert("Uploading failed!");
                    });
            }
            if (cardBack) {
                formData = new FormData();
                formData.append("file", this.b64ToBlob(cardBack), "card-1.jpg");

                this.axios
                    .post(
                        "https://lab-synapse.com/gql/storage/?type=id&ownerType=patients&ownerID=" +
                            this.patient.id,
                        formData
                    )
                    .then((res) => {
                        if (res.data.status == "uploaded") {
                            let obj = { id: res.data.fileID, fileDate: "Now" };
                            this.patient.ids.push(obj);
                            this.current_file = obj;
                        } else alert("Uploading failed!");
                    });
            }
        },
        b64ToBlob(data) {
            var byteString = atob(data);
            var u8arr = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                u8arr[i] = byteString.charCodeAt(i);
            }
            return new Blob([u8arr], { type: "image/jpeg" });
        },
    },
};
</script>