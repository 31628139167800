<template>
    <v-container v-if="$store.state.user.components.includes(90)">
        <grid
            label="Tox List"
            model="Tox"
            :conditions="toxConditions"
            :headers="toxHeaders"
            :canAdd="false"
            :canDelete="false"
            :canEdit="false"
            sort-by="label"
            sort-desc
            ref="grid"
        >
            <template v-slot:item.label="{value,item}">
                <a @click.prevent="editEreq(item.id)">{{value}}</a>
            </template>
        </grid>
        <v-dialog width="1200px" v-model="editDialog">
            <v-card :loading="loading">
                <v-card-title class="elevation-3">
                    <b>{{ereq.label}}</b>, status:
                    <b>{{ereq.status}}</b>, patient:
                    <b>{{ereq.patient_name}}</b>
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="editDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <br />
                    <v-tabs v-model="tab">
                        <v-tab
                            v-for="(codes,fid) in orderedCodes"
                            :key="fid"
                        >{{codes[0].facility_name}}</v-tab>
                        <v-tab>Results</v-tab>

                        <v-tab-item v-for="(codes,fid) in orderedCodes" :key="fid">
                            <v-card>
                                <v-card-text>
                                    <v-row v-for="(codes,fid) in orderedCodes" :key="fid" dense>
                                        <v-col cols="12" class="d-flex">
                                            <h2>Facility: {{codes[0].facility_name}}</h2>
                                            <v-spacer></v-spacer>

                                            <v-btn-toggle
                                                class="mr-3"
                                                v-for="(file,fk) in files[codes[0].facility_id]"
                                                :key="fk"
                                            >
                                                <v-btn small text @click="openPdf(file)">
                                                    <v-icon>mdi-paperclip</v-icon>
                                                    {{file.name}}
                                                </v-btn>
                                                <v-btn
                                                    small
                                                    icon
                                                    @click="files[codes[0].facility_id].splice(fk,1)"
                                                >
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-btn-toggle>

                                            <v-btn
                                                small
                                                outlined
                                                @click="selectFileToUpload(codes[0].facility_id)"
                                            >Upload PDF</v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" v-for="(code,k) in codes" :key="k">
                                            <div class="d-flex">
                                                <h4>{{code.code_label}} [{{code.code}}]</h4>
                                                <v-spacer />
                                                <v-btn
                                                    icon
                                                    small
                                                    @click="result[code.facility_id][code.id].push({})"
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </div>
                                            <v-row
                                                dense
                                                v-for="(res,rk) in result[code.facility_id][code.id]"
                                                :key="rk"
                                            >
                                                <v-col cols="1">
                                                    <v-text-field
                                                        label="Test Code"
                                                        hide-details="auto"
                                                        v-model="res.drug_code"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        label="Test Name"
                                                        hide-details="auto"
                                                        v-model="res.drug_name"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-text-field
                                                        label="Quantity"
                                                        hide-details="auto"
                                                        v-model="res.quantity"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-text-field
                                                        label="Unit"
                                                        hide-details="auto"
                                                        v-model="res.units"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-text-field
                                                        label="Cutoff"
                                                        hide-details="auto"
                                                        v-model="res.cutoff"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="2">
                                                    <v-select
                                                        label="Result"
                                                        hide-details="auto"
                                                        v-model="res.result"
                                                    ></v-select>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        label="Notes"
                                                        hide-details="auto"
                                                        v-model="res.notes"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="auto" align-self="center">
                                                    <v-btn
                                                        icon
                                                        small
                                                        @click="result[code.facility_id][code.id].splice(rk,1)"
                                                        :disabled="result[code.facility_id][code.id].length<=1"
                                                    >
                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :disabled="loading"
                                        color="primary"
                                        outlined
                                        class="mr-5"
                                        @click="save()"
                                    >Save Result</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <grid
                                    label="Results"
                                    model="ToxResult"
                                    :headers="gh"
                                    :conditions="gc"
                                    :canAdd="false"
                                    :canEdit="false"
                                    :canDelete="true"
                                >
                                    <template v-slot:item.Files="{item}">
                                        <v-btn
                                            small
                                            text
                                            v-for="file in item.Files"
                                            :key="file.id"
                                            target="_blank"
                                            :href="`https://lab-synapse.com/gql/storage?id=${file.id}`"
                                        >
                                            <v-icon>mdi-paperclip</v-icon>
                                            {{file.original_file}}
                                        </v-btn>
                                    </template>
                                </grid>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>
            </v-card>
        </v-dialog>
        <input type="file" name="file" style="visibility: hidden;" ref="fileInput" multiple />
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import grid from "../components/GRID.vue";
export default {
    components: { grid },
    data() {
        return {
            tab: null,
            ereq: {},
            ereq_id: null,
            result: {},
            results: [],
            uploading: false,
            files: {},
            editDialog: false,
            gh: [
                { value: "id", text: "ID" },
                { value: "status", text: "Status" },
                { value: "facility_name", text: "Facility Name" },
                { value: "type", text: "Type" },
                { value: "reported", text: "Reported" },
                {
                    value: "Files",
                    src: 'Files(where:[{status:{eq:"OK"}},{deleted:{is:null}}]){id original_file}',
                    text: "Files",
                    slot: true,
                },
            ],
            toxHeaders: [
                { text: "id", value: "id", visible: false, isId: true },
                { text: "eReq #", value: "label", slot: true },
                { text: "Status", value: "status" },
                { text: "Patient Name", value: "patient_name" },
                { text: "Physician", value: "physician_name" },
                { text: "Facility", value: "facility_name" },
                { text: "Collector", value: "collector_name" },
                { text: "User", value: "created_by_name" },
            ],
            toxConditions: [
                { deleted: { is: null } },
                { status: { in: ["Sent", "Sending"] } },
            ],
        };
    },
    watch: {
        editDialog(n) {
            if (!n) this.ereq_id = null;
        },
    },
    computed: {
        gc() {
            return [
                { tox_id: { eq: this.ereq_id } },
                { deleted: { is: null } },
            ];
        },
        loading() {
            return this.uploading || this.$apollo.queries?.ereq?.loading;
        },
        orderedCodes() {
            if (!(this.ereq?.OrderedCodes instanceof Array)) return [];

            let map = {};
            this.ereq.OrderedCodes.forEach((v) => {
                if (map[v.facility_id] == undefined)
                    this.$set(map, v.facility_id, []);
                map[v.facility_id].push(v);

                if (this.result[v.facility_id] == undefined)
                    this.$set(this.result, v.facility_id, {});
                if (this.result[v.facility_id][v.id] == undefined)
                    this.$set(this.result[v.facility_id], v.id, [{}]);

                if (this.files[v.facility_id] == undefined)
                    this.$set(this.files, v.facility_id, []);
            });
            return map;
        },
    },
    methods: {
        editEreq(id) {
            this.editDialog = true;
            this.ereq_id = id;
        },
        save() {
            let data = [];
            if (this.result instanceof Object) {
                Object.keys(this.result).forEach((fID) => {
                    let temp = [];
                    if (this.result[fID] instanceof Object) {
                        Object.keys(this.result[fID]).forEach((oID) => {
                            if (this.result[fID][oID] instanceof Array) {
                                this.result[fID][oID].forEach((obj) => {
                                    let hasVal = Object.values(obj).reduce(
                                        (acc, v) => acc || !!v,
                                        false
                                    );
                                    if (hasVal) {
                                        temp.push({
                                            ...obj,
                                            ordered_code_id: oID,
                                        });
                                    }
                                });
                            }
                        });
                    }
                    if (temp.length > 0 || this.files[fID].length > 0) {
                        data.push({
                            tox_id: this.ereq_id,
                            facility_id: fID,
                            type: "Manual",
                            status: "Final",
                            Details: temp,
                        });
                    }
                });
            }

            if (data.length <= 0) return;

            console.log(data);

            this.uploading = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($data: [ToxResultSave]!) {
                            ToxResultsSave(data: $data) {
                                id
                                facility_id
                            }
                        }
                    `,
                    variables: {
                        data: data,
                    },
                })
                .then((res) => {
                    if (res.data.ToxResultsSave instanceof Array) {
                        let promises = [];
                        res.data.ToxResultsSave.forEach((v) => {
                            if (this.files[v.facility_id].length > 0) {
                                this.files[v.facility_id].forEach((f) => {
                                    promises.push(
                                        this.uploadFile(
                                            f,
                                            f.name,
                                            "toxResult",
                                            "tox_result",
                                            v.id
                                        )
                                    );
                                });
                            }
                        });
                        Promise.all(promises)
                            .then(() => {
                                return this.updateEreqStatus(this.ereq_id);
                            })
                            .finally(() => {
                                this.ereq = {};
                                this.files = {};
                                this.uploading = false;
                                this.editDialog = false;
                                this.$refs.grid.reload();

                                //this.$apollo.queries.ereq.refetch();
                                //this.tab = this.orderedCodes.length;
                            });
                    }
                })
                .catch(() => {
                    this.uploading = false;
                });
        },
        async openPdf(file) {
            //const blob = await fetch(file).then((r) => r.blob());
            const dataUrl = window.URL.createObjectURL(file);
            window.open(dataUrl);
        },
        async uploadFile(blob, fileName, type, ownerType, ownerID) {
            const formData = new FormData();
            formData.append("file", blob, fileName);
            let res = null;

            res = await this.axios.post(
                `https://lab-synapse.com/gql/storage/?type=${type}&ownerType=${ownerType}&ownerID=${ownerID}`,
                formData
            );

            if (res.data.status == "uploaded") {
                return {
                    id: res.data.fileID,
                    src: `https://lab-synapse.com/gql/storage?id=${res.data.fileID}`,
                    error: null,
                };
            } else throw new Error(res.data.error);
        },
        selectFileToUpload(facility_id) {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.setAttribute("facility_id", facility_id);
            this.$refs.fileInput.accept = ".pdf";
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener(
                "change",
                this._uploadFile
            );
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            if (e.target.files && this.ereq_id) {
                const facilityID = e.target.getAttribute("facility_id");

                e.target.files.forEach((file) => {
                    this.files[facilityID].push(file);
                });
            }
        },
        updateEreqStatus(id) {
            return this.$apollo.mutate({
                mutation: gql`
                    mutation ($data: [ToxSave]!) {
                        ToxsSave(data: $data) {
                            id
                            status
                            facility_id
                        }
                    }
                `,
                variables: {
                    data: [{ id: id, status: "Complete" }],
                },
            });
        },
    },
    apollo: {
        ereq: {
            query: gql`
                query ($id: ID!, $where: [ToxOrderedCodeConditionAND]) {
                    Tox(id: $id) {
                        id
                        label
                        status
                        patient_name
                        physician_name
                        collector_name
                        created_by_name
                        OrderedCodes(where: $where) {
                            id
                            facility_id
                            facility_name
                            code_label
                            code
                            code_abrv
                        }
                    }
                }
            `,
            skip() { return !this.ereq_id },
            fetchPolicy: "network-only",
            variables() {
                return {
                    id: this.ereq_id,
                    where: [
                        { deleted: { is: null } },
                        {
                            facility_id: {
                                in: this.$store.state.user.payload.ls,
                            },
                        },
                    ],
                };
            },
            update(data) {
                return data.Tox;
            },
        },
        /*results: {
            query: gql`
                query (
                    $where1: [ToxResultConditionAND]
                    $where2: [ToxResultDetailConditionAND]
                ) {
                    ToxResults(where: $where1) {
                        id
                        tox_id
                        facility_id
                        type
                        status
                        reported
                        Details(where: $where2) {
                            id
                            status
                            drug_code
                            drug_name
                            quantity
                            cutoff
                            result
                            units
                            notes
                        }
                    }
                }
            `,
            fetchPolicy: "network-only",
            variables() {
                return {
                    where1: [
                        { facility_id: { eq: 1 } },
                        { deleted: { is: null } },
                    ],
                    where2: [{ deleted: { is: null } }],
                };
            },
            update(data) {
                return data.ToxResults;
            },
        },*/
    },
};
</script>