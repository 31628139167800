<template>
    <v-container class="py-0" fluid>
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="100%">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col>
                                <h3>Prescribed Medications</h3>
                            </v-col>
                            <v-col align="right">
                                <v-btn small v-on:click="medications.push({})">
                                    <v-icon>mdi-plus</v-icon>Medication
                                </v-btn>
                            </v-col>
                        </v-row>
                        <br />
                        <v-divider></v-divider>
                        <v-row v-for="(med, mkey) in activeMeds" :key="'mk_'+mkey" dense>
                            <v-col cols="4">
                                <autocomplete
                                    model="Medication"
                                    item-value="id"
                                    item-text="name"
                                    label="Medication"
                                    v-model="med.medication_id"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    placeholder=" "
                                    :items="med.Medication ? [{id:med.Medication.id,name:med.Medication.name}] : undefined"
                                ></autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Dose"
                                    v-model="med.dosage"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    placeholder=" "
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-select
                                    label="Unit"
                                    :items="['NG', 'UG', 'mg', 'g','MCG','TABS']"
                                    v-model="med.measure"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    placeholder=" "
                                ></v-select>
                            </v-col>
                            <v-col cols="3">
                                <datepicker
                                    label="Prescribed"
                                    v-model="med.prescribed"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    placeholder=" "
                                ></datepicker>
                            </v-col>
                            <v-col cols="1" align="right">
                                <v-btn icon outlined>
                                    <v-icon v-on:click="delMed(med)">mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card>
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col>
                                <h3>Point of Care Test</h3>
                            </v-col>
                        </v-row>
                        <br />
                        <v-divider></v-divider>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="poc_headers" :items="pocs" dense>
                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.pos="{item}">
                                        <v-checkbox
                                            hide-details="auto"
                                            style="margin:0 0 3px 0"
                                            v-model="item.result"
                                            value="Positive"
                                        ></v-checkbox>
                                    </template>
                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:item.neg="{item}">
                                        <v-checkbox
                                            hide-details="auto"
                                            style="margin:0 0 3px 0"
                                            height="1"
                                            v-model="item.result"
                                            value="Negative"
                                        ></v-checkbox>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.down()">
                    <v-icon>mdi-arrow-left-bold</v-icon>BACK
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.save()">
                    <v-icon>mdi-content-save</v-icon>Save & Close
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn
                    rounded
                    v-on:click="stepUp()"
                    :disabled="!stepAllowed"
                    :color="stepAllowed?'green':null"
                >
                    NEXT
                    <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import autocomplete from "../../components/AutoComplete.vue";
import datepicker from "../../components/DatePickerInput";
import gql from "graphql-tag";
export default {
    data() {
        return {
            medications: [{}, {}, {}, {}, {}],
            toxMeds: [],
            patMeds: [],
            pocs: [],
            pocs_map: {},
            poc_headers: [
                { value: "name", text: "Name" },
                { value: "pos", text: "Positive" },
                { value: "neg", text: "Negative" },
            ],
            stepAllowed: true,
        };
    },
    watch: {
        tox_id: {
            handler(n) {
                if (n != undefined) {
                    this.getPocs();
                    this.getToxMeds();
                }
            },
            immediate: true,
            deep: true,
        },
        patient_id: {
            handler(n) {
                if (n != undefined) this.getPatMeds();
            },
            immediate: true,
            deep: true,
        },
        pocs: {
            handler(n) {
                this.$emit("update:pocs", n);
            },
            deep: true,
        },
        medications: {
            handler(n) {
                this.$emit("update:meds", n);
            },
            deep: true,
        },
    },
    computed: {
        activeMeds() {
            return this.medications.filter(
                (v) => !(!!v._delete || !!v.deleted)
            );
        },
    },
    methods: {
        delMed(med) {
            if (med.patient_medication_id || med.tox_medication_id)
                this.$set(med, "_delete", true);
            else this.medications.splice(this.medications.indexOf(med), 1);
        },
        findMed(id) {
            return this.medications.filter((v) => v.id == id)[0];
        },
        calcMedications() {
            let tmeds = JSON.parse(JSON.stringify(this.toxMeds));
            let pmeds = JSON.parse(JSON.stringify(this.patMeds));

            tmeds.forEach((tv) => {
                let matchByIdObj = null;
                let matchByIdKey = null;
                pmeds.forEach((pv, pk) => {
                    if (pv.id == tv.patient_medication_id) {
                        matchByIdObj = pv;
                        matchByIdKey = pk;
                    }
                    pv.__typename = undefined;
                });

                tv.tox_medication_id = tv.id;
                tv.id = undefined;
                tv.__typename = undefined;

                if (matchByIdObj) {
                    // Match or Modified
                    pmeds.splice(matchByIdKey, 1);
                    tv.patient_medication_id = matchByIdObj.id;
                    tv.tmeds = JSON.parse(JSON.stringify(tv));
                    tv.pmeds = matchByIdObj;
                    tv.state = "Match";
                    tv.match =
                        tv.medication_id == matchByIdObj.medication_id &&
                        tv.dosage == matchByIdObj.dosage &&
                        tv.measure == matchByIdObj.measure &&
                        tv.prescribed == matchByIdObj.prescribed;
                } else if (tv.patient_medication_id > 0) {
                    // Deleted
                    tv.state = "Deleted";
                } else {
                    // New
                    tv.state = "New";
                }
            });

            pmeds.forEach((pv) => {
                tmeds.push({
                    ...pv,
                    state: "New",
                    id: undefined,
                    patient_medication_id: pv.id,
                });
            });

            this.medications = tmeds.filter(
                (v) => !(!!v._delete || !!v.deleted)
            );
        },
        getPatMeds() {
            this.$apollo
                .query({
                    query: gql`
                    {
                        PatientMedications(where: [{ deleted: { is: null } },{ patient_id: { eq: ${this.patient_id} } }]) {
                            Medication {
                                id
                                name
                            }
                            id
                            medication_id    
                            dosage
                            measure
                            prescribed
                        }
                    }
                `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.PatientMedications instanceof Array) {
                        this.patMeds = res.data.PatientMedications;
                        this.calcMedications();
                    }
                });
        },
        getToxMeds() {
            this.$apollo
                .query({
                    query: gql`
                    {
                        ToxMedications(where: [{ deleted: { is: null } },{ tox_id: { eq: ${this.tox_id} } }]) {
                            Medication {
                                id
                                name
                            }
                            id
                            medication_id    
                            patient_medication_id
                            dosage
                            measure
                            prescribed
                        }
                    }
                `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.ToxMedications instanceof Array) {
                        this.toxMeds = res.data.ToxMedications;
                        this.calcMedications();
                    }
                });
        },
        saveMeds() {
            let meds = JSON.parse(JSON.stringify(this.medications));
            meds = meds.filter((v) => v.medication_id > 0);
            meds = meds.map((v) => {
                return {
                    ...v,
                    patient_id: this.patient_id,
                    tox_id: this.tox_id,
                    __typename: undefined,
                    state: undefined,
                    match: undefined,
                    tmeds: undefined,
                    pmeds: undefined,
                    deleted: undefined,
                    Medication: undefined,
                    medication_name: undefined,
                };
            });

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($meds: [ToxSaveMedicationsArg]!) {
                            ToxSaveMedications(medications: $meds) {
                                tox_medications {
                                    id
                                    tox_id
                                    patient_medication_id
                                    medication_id
                                    medication_name
                                    dosage
                                    measure
                                    prescribed
                                    deleted
                                }
                                patient_medications {
                                    id
                                    patient_id
                                    medication_id
                                    medication_name
                                    dosage
                                    measure
                                    prescribed
                                    deleted
                                }
                            }
                        }
                    `,
                    variables: {
                        meds: meds,
                    },
                })
                .then((res) => {
                    if (
                        res.data.ToxSaveMedications.tox_medications instanceof
                        Array
                    ) {
                        this.toxMeds = [];
                        res.data.ToxSaveMedications.tox_medications.forEach(
                            (v) => {
                                if (v.deleted == null) {
                                    this.toxMeds.push({
                                        ...v,
                                        Medication: {
                                            id: v.medication_id,
                                            name: v.medication_name,
                                        },
                                        medication_name: undefined,
                                    });
                                }
                            }
                        );
                    }
                    if (
                        res.data.ToxSaveMedications
                            .patient_medications instanceof Array
                    ) {
                        this.patMeds = [];
                        res.data.ToxSaveMedications.patient_medications.forEach(
                            (v) => {
                                if (v.deleted == null) {
                                    this.patMeds.push({
                                        ...v,
                                        Medication: {
                                            id: v.medication_id,
                                            name: v.medication_name,
                                        },
                                        medication_name: undefined,
                                    });
                                }
                            }
                        );
                    }
                    this.calcMedications();
                })
                .finally(() => {});
        },

        getPocs() {
            this.$apollo
                .query({
                    query: gql`
                        {
                            POCs(where: [{ deleted: { is: null } }]) {
                                id
                                name
                                abbrv
                            }
                            ToxPocs(where: [{ deleted: { is: null } }, { tox_id: { eq: ${this.tox_id} } }]) {
                                id
                                poc_id
                                result
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.POCs instanceof Array) {
                        this.pocs = res.data.POCs;
                        delete this.pocs.__typename;
                        this.pocs.forEach((v) => {
                            this.pocs_map[v.id] = v;
                        });
                    }
                    if (res.data.ToxPocs instanceof Array) {
                        res.data.ToxPocs.forEach((v) => {
                            if (this.pocs_map[v.poc_id]) {
                                this.$set(
                                    this.pocs_map[v.poc_id],
                                    "result",
                                    v.result
                                );
                                this.$set(
                                    this.pocs_map[v.poc_id],
                                    "tox_poc_id",
                                    v.id
                                );
                            }
                        });
                    }
                });
        },
        savePoc() {
            let vals = [];
            this.pocs.forEach((v) => {
                if (v.result !== undefined) {
                    vals.push({
                        id: v.tox_poc_id,
                        tox_id: this.tox_id,
                        poc_id: v.id,
                        poc_name: v.name,
                        poc_abbrv: v.abbrv,
                        result: v.result != null ? v.result : undefined,
                        _delete:
                            v.result == null && v.tox_poc_id > 0
                                ? true
                                : undefined,
                    });
                }
            });
            if (vals.length <= 0) return;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation($toxPocs: [ToxPocSave]!) {
                            ToxPocsSave(data: $toxPocs) {
                                id
                                poc_id
                                result
                                deleted
                            }
                        }
                    `,
                    variables: {
                        toxPocs: vals,
                    },
                })
                .then((res) => {
                    if (res.data.ToxPocsSave instanceof Array) {
                        res.data.ToxPocsSave.forEach((v) => {
                            if (this.pocs_map[v.poc_id]) {
                                if (v.deleted == null) {
                                    this.$set(
                                        this.pocs_map[v.poc_id],
                                        "result",
                                        v.result
                                    );
                                    this.$set(
                                        this.pocs_map[v.poc_id],
                                        "tox_poc_id",
                                        v.id
                                    );
                                } else {
                                    this.$set(
                                        this.pocs_map[v.poc_id],
                                        "result",
                                        undefined
                                    );
                                    this.$set(
                                        this.pocs_map[v.poc_id],
                                        "tox_poc_id",
                                        undefined
                                    );
                                }
                            }
                        });
                    }
                });
        },
        stepUp() {
            //this.savePoc();
            //this.saveMeds();

            this.step.up();
        },
    },
    components: {
        autocomplete,
        datepicker,
    },
    mounted() {},
    props: ["step", "tox_id", "patient_id"],
};
</script>
<style scoped>
.v-data-table th {
    padding: 0 10px;
}
</style>