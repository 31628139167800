<template>
    <div :style="style" id="flask"></div>
</template>
<script>
    export default {
        props: ['label','level','width','height','color'],
        data() {
            return {
                
            }
        },
        computed: {
            style: function() {
                let width;
                let height;

                if(this.width) width=this.width;
                else width="100px";

                if(this.height) height=this.height;
                else  height="200px";
                   
                return { backgroundImage: 'url("data:image/svg+xml;base64,'+btoa(this.svg)+'")', width: width, height: height};
            },
            svg: function() {
                let label = "SAMPLE";
                let level = 2;
                
                if(this.label!=null) label=this.label;
                if(this.level!=null) level=this.level;
                
                if(level<0) level=0;
                if(level>10) level=10;

                let l = 1100 - (level * 58);

                return `<svg xmlns:dc="http://purl.org/dc/elements/1.1/"
                    xmlns:cc="http://web.resource.org/cc/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="171" height="747.5" id="svg2"
                    sodipodi:version="0.32" inkscape:version="0.45.1" sodipodi:docbase="/home/gringer/inkscape"
                    sodipodi:docname="vacuum_bloodtube.svg" inkscape:output_extension="org.inkscape.output.svg.inkscape"
                    version="1.0" preserveAspectRatio="xMidYMid meet">
                    <defs id="defs4">
                        <linearGradient id="linearGradient9278">
                            <stop style="stop-color:#838383;stop-opacity:1;" offset="0" id="stop9280" />
                            <stop style="stop-color:#f0f0f0;stop-opacity:1;" offset="1" id="stop9282" />
                        </linearGradient>
                        <linearGradient id="linearGradient6316">
                            <stop style="stop-color:#800000;stop-opacity:1;" offset="0" id="stop6318" />
                            <stop style="stop-color:#ff7171;stop-opacity:1;" offset="1" id="stop6320" />
                        </linearGradient>
                        <linearGradient inkscape:collect="always" xlink:href="#linearGradient6316" id="linearGradient6322" x1="8.5"
                            y1="543.79077" x2="162.5" y2="545.68188" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(209.5,421.86218)" />
                        <linearGradient inkscape:collect="always" xlink:href="#linearGradient9278" id="linearGradient9284"
                            x1="9.2867451" y1="531.927" x2="151.35957" y2="531.927" gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(209.5,421.86218)" />
                    </defs>
                    <sodipodi:namedview id="base" pagecolor="#ffffff" bordercolor="#666666" borderopacity="1.0"
                        gridtolerance="10000" guidetolerance="10" objecttolerance="10" inkscape:pageopacity="0.0"
                        inkscape:pageshadow="2" inkscape:zoom="0.49497475" inkscape:cx="285.81323" inkscape:cy="354.5987"
                        inkscape:document-units="px" inkscape:current-layer="layer1" showgrid="true" inkscape:grid-points="true"
                        inkscape:window-width="1177" inkscape:window-height="760" inkscape:window-x="64" inkscape:window-y="168" />
                    <metadata id="metadata7">
                        <rdf:RDF>
                            <cc:Work rdf:about="">
                                <dc:format>image/svg+xml</dc:format>
                                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                                <dc:title>Blood Tube</dc:title>
                                <dc:date>2007-10-25</dc:date>
                                <dc:creator>
                                    <cc:Agent>
                                        <dc:title>David Hall (gringer)</dc:title>
                                    </cc:Agent>
                                </dc:creator>
                                <cc:license rdf:resource="http://web.resource.org/cc/PublicDomain" />
                                <dc:subject>
                                    <rdf:Bag>
                                        <rdf:li>blood tube sample container biology
                                            biochemistry</rdf:li>
                                    </rdf:Bag>
                                </dc:subject>
                                <dc:description>An image of a vaccuum-style blood tube,
                                    with label</dc:description>
                            </cc:Work>
                            <cc:License rdf:about="http://web.resource.org/cc/PublicDomain">
                                <cc:permits rdf:resource="http://web.resource.org/cc/Reproduction" />
                                <cc:permits rdf:resource="http://web.resource.org/cc/Distribution" />
                                <cc:permits rdf:resource="http://web.resource.org/cc/DerivativeWorks" />
                            </cc:License>
                        </rdf:RDF>
                    </metadata>
                    <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-209.5,-421.86218)">
                        <path id="path5310" style="fill:url(#linearGradient6322);fill-opacity:`+(level==0?0:1)+`.0;" 
                            d="M 220,`+l+` L 220,1112.3622 C 220,1142.7222 250.84,1167.3622 295,1167.3622 C 339.16,1167.3622 370,1142.7222 370,1112.3622 L 370,`+l+`"
                            sodipodi:nodetypes="ccscc" />
                        <path id="path5310"
                            style="fill:none;fill-opacity:1.0;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                            d="M 220,457.36218 L 220,1112.3622 C 220,1142.7222 250.84,1167.3622 295,1167.3622 C 339.16,1167.3622 370,1142.7222 370,1112.3622 L 370,457.36218"
                            sodipodi:nodetypes="ccscc" />
                        <path id="path5271"
                            style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dashoffset:0;stroke-opacity:1"
                            d="M 380,442.36218 C 380,431.32218 380,501.32218 380,512.36218 C 380,523.40218 341.92,532.36218 295,532.36218 C 248.08,532.36218 210,523.40218 210,512.36218 C 210,501.32218 210,431.32218 210,442.36218 C 210,453.40218 248.08,462.36218 295,462.36218 C 341.92,462.36218 380,453.40218 380,442.36218 z "
                            sodipodi:nodetypes="ccsccsc" />
                        <path id="path4255"
                            style="fill:none;fill-opacity:1;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dashoffset:0;stroke-opacity:1"
                            d="M 342.51367,528.94499 L 342.51367,458.94499 M 355.095,456.50218 L 355.095,526.50218 M 328.07563,530.78968 L 328.07563,460.78968 M 365.47695,453.54187 L 365.47695,523.54187 M 312.12352,531.95562 L 312.12352,461.95562 M 373.31687,450.14468 L 373.31687,520.14468 M 295,532.36218 L 295,462.36218 M 378.27211,446.39124 L 378.27211,516.39124 M 277.87648,531.95562 L 277.87648,461.95562 M 380,442.36218 L 380,512.36218 M 261.92437,530.78968 L 261.92437,460.78968 M 247.48633,528.94499 L 247.48633,458.94499 M 234.905,526.50218 L 234.905,456.50218 M 224.52305,523.54187 L 224.52305,453.54187 M 216.68313,520.14468 L 216.68313,450.14468 M 211.72789,516.39124 L 211.72789,446.39124 M 210,442.36218 L 210,512.36218"
                            sodipodi:nodetypes="cccccccccccccccccccccccccccccccccc" />
                        <path sodipodi:type="arc"
                            style="fill:#ffcc00;fill-opacity:1;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                            id="path5256" sodipodi:cx="295" sodipodi:cy="442.36218" sodipodi:rx="85" sodipodi:ry="20"
                            d="M 380 442.36218 A 85 20 0 1 1  210,442.36218 A 85 20 0 1 1  380 442.36218 z" />
                        <!--<path
                            style="fill:#d40000;fill-opacity:1;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                            d="M 339.5625,443.375 C 339.40876,443.03254 339.3992,442.67518 339.09375,442.34375 C 338.49398,441.69297 337.60781,441.06687 336.46875,440.46875 C 335.32969,439.87063 333.93039,439.31328 332.3125,438.78125 C 330.69461,438.24922 328.84875,437.73375 326.8125,437.28125 C 322.74,436.37625 317.88313,435.6625 312.5,435.15625 C 307.11687,434.65 301.21,434.375 295,434.375 C 288.79,434.375 282.88313,434.65 277.5,435.15625 C 272.11687,435.6625 267.26,436.37625 263.1875,437.28125 C 261.15125,437.73375 259.30539,438.24922 257.6875,438.78125 C 256.06961,439.31328 254.67031,439.87063 253.53125,440.46875 C 252.39219,441.06687 251.50602,441.69297 250.90625,442.34375 C 250.6008,442.67518 250.59124,443.03254 250.4375,443.375 C 252.731,448.41837 271.70338,452.375 295,452.375 C 318.29662,452.375 337.269,448.41837 339.5625,443.375 z "
                            id="path5269" />-->
                        <path
                            style="fill:none;fill-opacity:1;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                            d="M 295,432.375 C 270.16,432.375 250,436.855 250,442.375 C 250,442.71797 250.28556,443.04088 250.4375,443.375 C 250.59124,443.03254 250.6008,442.67518 250.90625,442.34375 C 251.50602,441.69297 252.39219,441.06687 253.53125,440.46875 C 254.67031,439.87063 256.06961,439.31328 257.6875,438.78125 C 259.30539,438.24922 261.15125,437.73375 263.1875,437.28125 C 267.26,436.37625 272.11687,435.6625 277.5,435.15625 C 282.88313,434.65 288.79,434.375 295,434.375 C 301.21,434.375 307.11687,434.65 312.5,435.15625 C 317.88313,435.6625 322.74,436.37625 326.8125,437.28125 C 328.84875,437.73375 330.69461,438.24922 332.3125,438.78125 C 333.93039,439.31328 335.32969,439.87063 336.46875,440.46875 C 337.60781,441.06687 338.49398,441.69297 339.09375,442.34375 C 339.3992,442.67518 339.40876,443.03254 339.5625,443.375 C 339.71444,443.04088 340,442.71797 340,442.375 C 340,436.855 319.84,432.375 295,432.375 z "
                            id="path5260" />
                        <path
                            style="fill:url(#linearGradient9284);fill-opacity:1;stroke:#000000;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                            d="M 230,602.36218 L 230,862.36218 C 242.89052,871.4533 267.02179,877.67468 295,877.67468 C 322.97821,877.67468 347.10948,871.4533 360,862.36218 L 360,602.36218 C 347.10948,611.4533 322.97821,617.67468 295,617.67468 C 267.02179,617.67468 242.89052,611.4533 230,602.36218 z "
                            id="path7313" sodipodi:nodetypes="ccsccsc" />
                        <text xml:space="preserve"
                            style="font-size:36px;font-style:normal;font-weight:normal;fill:#000000;fill-opacity:1;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;font-family:Arial"
                            x="659.37878" y="-282.11523" id="text9286" transform="matrix(0,1,-1,0,0,0)">
                            <tspan sodipodi:role="line" id="tspan9288" x="659.37878" y="-282.11523">`+label+`</tspan>
                        </text>
                    </g>
                </svg>`;
            }
        },
        mounted() {
            //window.console.log(this.svg);
        }
    }
</script>
<style scoped>
#flask {
    background-size: contain;
    background-repeat: no-repeat;
}



</style>