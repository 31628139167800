<template>
    <v-container class="py-0" fluid>
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="100%">
                    <v-card-title>Collector, Collection Time and Diagnosis</v-card-title>
                    <v-card-text>
                        <v-row dense>
                            <v-col>
                                <autocomplete
                                    model="User"
                                    item-value="id"
                                    item-text="name"
                                    label="Collector"
                                    hide-details="auto"
                                    :initialItems="citems"
                                    v-model="dt.collector"
                                    :conditions="[{facility_id:{eq:facility_id}}]"
                                ></autocomplete>
                            </v-col>
                            <v-col class="wrap-text">
                                <label class="v-label" for="tempInRange">
                                    Temp in range (90-100)
                                    <v-radio-group
                                        dense
                                        :column="false"
                                        hide-details="auto"
                                        id="tempInRange"
                                        class="mt-0"
                                        v-model="dt.temp"
                                    >
                                        <v-radio
                                            hide-details="auto"
                                            v-for="i in ['Yes','No','Unknown']"
                                            :key="i"
                                            :label="i"
                                            :value="i"
                                        >{{i}}</v-radio>
                                    </v-radio-group>
                                </label>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="6">
                                <datepicker
                                    label="Collected Date"
                                    hide-details="auto"
                                    mandatory
                                    v-model="dt.collected_date"
                                ></datepicker>
                            </v-col>
                            <v-col cols="6">
                                <timepicker
                                    label="Collected Time"
                                    hide-details="auto"
                                    mandatory
                                    v-model="dt.collected_time"
                                ></timepicker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <cptMatrix
                                    :cpt_codes="cpts"
                                    v-model="cptDgMap"
                                    :selected_diagnosis="selected_diagnosis"
                                    :cpt_dg_map="cpt_dg_map"
                                    :collected="dt.collected_date"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <FileBox :tox_id="tox_id" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card height="100%">
                    <v-card-text>
                        <h2>Labs & Containers</h2>
                        <v-container fluid v-for="lab in tests_cpts.tests" :key="lab.id" class>
                            <h3>
                                <li>{{lab.name}}</li>
                            </h3>

                            <v-row dense>
                                <v-col v-for="(container,ck) in lab.containers" :key="ck">
                                    <table>
                                        <tr>
                                            <td
                                                rowspan="3"
                                                v-for="(flask,fk) in container.flasks"
                                                :key="fk"
                                            >
                                                <Flask
                                                    :level="(1 - flask.left/container.volume) * 10"
                                                    height="50px"
                                                    width="15px"
                                                    :label="container.name"
                                                />
                                            </td>
                                            <td align="center">
                                                <b>{{container.name}}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="center">
                                                <i>{{container.sample_name}}</i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                align="center"
                                            >{{getFlaskSubtitle(container.flasks, container)}}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row dense v-if="lab.Settings">
                                <v-col>{{lab.Settings.Disclaimer}}</v-col>
                            </v-row>
                        </v-container>

                        <br />

                        <v-row dense>
                            <v-col cols="6">
                                <SignBox
                                    title="Patient Signature"
                                    :required="patSigRequired"
                                    type="patientSignature"
                                    :id="tox_id"
                                    v-model="dt.patient_signature"
                                ></SignBox>
                            </v-col>
                            <v-col cols="6">
                                <SignBox
                                    title="Physician Signature"
                                    :required="phySigRequired"
                                    type="physicianSignature"
                                    :id="tox_id"
                                    v-model="dt.physician_signature"
                                    :user="$store.state.user.payload.id == physician_id ? physician_id : null"
                                ></SignBox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="space-around">
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.down()">
                    <v-icon>mdi-arrow-left-bold</v-icon>BACK
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.save()">
                    <v-icon>mdi-content-save</v-icon>Save & Close
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn
                    rounded
                    v-on:click="step.up()"
                    :disabled="!stepAllowed"
                    :color="stepAllowed?'green':null"
                >
                    FINISH
                    <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import autocomplete from "../../components/AutoComplete.vue";

import SignBox from "./SignBox.vue";
import Flask from "../../components/Flask.vue";
import cptMatrix from "./cptMatrix";
import datepicker from "../../components/DatePickerInput";
import timepicker from "../../components/TimePickerInput";
import FileBox from "./FileBox.vue";
export default {
    components: {
        autocomplete,
        datepicker,
        timepicker,
        SignBox,
        Flask,
        cptMatrix,
        FileBox,
    },
    data() {
        return {
            dt: {
                collector: null,
                temp: null,
                collected_date: null,
                collected_time: null,
                physician_signature: null,
                patient_signature: null,
            },

            cptDgMap: {},
            citems: [],
        };
    },
    methods: {
        getFlaskSubtitle(flasks, container) {
            let res = "";

            let fs = {};
            flasks.forEach((f) => {
                if (fs[f.left] === undefined) fs[f.left] = [];
                fs[f.left].push(f);
            });
            Object.keys(fs).forEach((k) => {
                res += fs[k].length + " x " + (container.volume - k) + " uL, ";
            });
            return res;
        },

        prevStep() {
            this.$emit("step", 3);
        },
        nextStep() {
            this.$emit("step", 5);
        },
    },
    watch: {
        dt: {
            handler() {
                this.$emit("input", { dt: this.dt, map: this.cptDgMap });
            },
            deep: true,
        },
        cptDgMap: {
            handler() {
                this.$emit("input", { dt: this.dt, map: this.cptDgMap });
            },
            deep: true,
        },
        value: {
            handler(n) {
                if (n instanceof Object) {
                    this.dt = n.dt;
                    this.cptDgMap = n.map;
                }
            },
            immediate: true,
        },
        collector(n) {
            if (n instanceof Object && n.id) {
                this.citems.push(n);
                this.$set(this.dt, "collector", n.id);
            }
        },
        tempOk(n) {
            if (n) this.$set(this.dt, "temp", n);
        },
        collected(n) {
            if (n) {
                let dt = n.split(" ");
                this.$set(this.dt, "collected_date", dt[0]);
                this.$set(this.dt, "collected_time", dt[1]);
            }
        },
    },
    computed: {
        stepAllowed() {
            return (
                this.dt.collected_date &&
                this.dt.collected_time &&
                (!this.phySigRequired || this.dt.physician_signature) &&
                (!this.patSigRequired || this.dt.patient_signature)
            );
        },
        patSigRequired() {
            return this.tests_cpts?.tests?.reduce(
                (acc, v) => acc || v.Settings?.PatientSignatureRequire == 1,
                false
            );
        },
        phySigRequired() {
            return this.tests_cpts?.tests?.reduce(
                (acc, v) => acc || v.Settings?.PhysicianSignatureRequire == 1,
                false
            );
        },
        cpts() {
            if (
                this.tests_cpts instanceof Object &&
                this.tests_cpts.cpts instanceof Array
            ) {
                return this.tests_cpts.cpts.map((v) => v.cpt);
            } else return [];
        },
    },

    props: [
        "step",
        "tests_cpts",
        "value",
        "collector",
        "tempOk",
        "collected",
        "selected_diagnosis",
        "cpt_dg_map",
        "tox_id",
        "facility_id",
        "physician_id"
    ],
};
</script>