<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h3>Attached Documents</h3>
            </v-col>
            <v-col cols="auto">
                <v-btn small v-on:click="uploadFile()">
                    <v-icon>mdi-plus</v-icon>File
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="6" sm="4" md="3" lg="2" v-for="(file, fk) in files" :key="'fk_'+fk">
                <v-card :loading="file.loading" @click="showPicture(file)" height="100%">
                    <v-row dense>
                        <v-col cols="9">
                            <v-img
                                v-if="file.url && !file.error"
                                aspect-ratio="1"
                                :src="file.url"
                                @load="file.loading = false"
                                @error="setError(file,$event)"
                            >
                                <template v-slot:placeholder>
                                    <v-progress-circular indeterminate class="ma-auto"></v-progress-circular>
                                </template>
                            </v-img>
                            <v-icon v-else size="50" class="ma-3">mdi-paperclip</v-icon>
                        </v-col>
                        <v-col cols="3" class="pl-0 d-flex flex-column justify-space-around">
                            <v-btn icon small @click.stop="delFile(file)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>

                            <v-btn icon small>
                                <v-icon>mdi-magnify-plus-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <input type="file" name="file" style="visibility: hidden;" ref="fileInput" />
        <v-dialog v-model="pictureDialog.opened" width="900">
            <v-card :loading="pictureDialog.loading" height="80vh">
                <embed :src="pictureDialog.url" width="99%" height="99%" />
            </v-card>
        </v-dialog>
        <confirm v-model="dialogConfirm.opened" v-bind="dialogConfirm"></confirm>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import confirm from "../../components/dialogs/confirm";
export default {
    components: { confirm },
    data() {
        return {
            loading: false,
            files: [],
            dialogConfirm: { opened: false },
            pictureDialog: { opened: false, url: null, loading:false },
        };
    },
    props: {
        tox_id: [String, Number],
    },
    watch: {
        tox_id(n) {
            if (n) this.getFiles(n);
        },
    },
    methods: {
        showPicture(file) {
            this.pictureDialog = { opened: true, url: file.url };
        },
        setError(file) {
            this.$set(file, "error", true);
            this.$set(file, "loading", false);
        },
        getFiles(tox_id) {
            this.$apollo
                .query({
                    query: gql`
                        query {
                            Files(
                                where: [
                                    { owner_id: { eq: "${tox_id}" } },
                                    { owner_type: { eq: "tox" } },
                                    { type: { eq: "attachment" } },
                                    { deleted: { is: null } },
                                    { status: { eq: "OK" } },
                                ]
                            ) {
                                id
                                date
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    this.files = res.data.Files.map((v) => {
                        return {
                            ...v,
                            url:
                                "https://lab-synapse.com/gql/storage?id=" +
                                v.id,
                        };
                    });
                });
        },
        delFile(file) {
            this.dialogConfirm.title = "You are about to delete a file!";
            this.dialogConfirm.text =
                "Are you sure that you want to delete this file?";
            this.dialogConfirm.url = file.url;
            this.dialogConfirm.result = (confirmed) => {
                if (confirmed) {
                    file.loading = true;
                    this.$apollo
                        .mutate({
                            mutation: gql`
                            mutation {
                                FileDelete(where: [{ id: { eq: "${file.id}" } }]) {
                                    id
                                }
                            }
                        `,
                        })
                        .then((res) => {
                            if (res.data.FileDelete instanceof Array) {
                                res.data.FileDelete.forEach((v) => {
                                    this.files.splice(
                                        this.files.findIndex(
                                            (f) => f.id == v.id
                                        ),
                                        1
                                    );
                                });
                            }
                        });
                }
            };
            this.$set(this.dialogConfirm, "opened", true);
        },
        uploadFile() {
            this.$refs.fileInput.value = null;
            //this.$refs.fileInput.setAttribute("img-type", type);
            this.$refs.fileInput.accept = ".gif,.jpg,.jpeg,.bmp,.svg,.pdf";
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener(
                "change",
                this._uploadFile
            );
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            if (!this.tox_id) return;

            let newFile = {
                url: null,
                loading: true,
                uploaded: false,
                error: false,
            };
            this.files.push(newFile);

            const file = e.target.files[0];
            const formData = new FormData();
            formData.append("file", file, file.filename);
            this.axios
                .post(
                    "https://lab-synapse.com/gql/storage/?type=attachment&ownerType=tox&ownerID=" +
                        this.tox_id,
                    formData
                )
                .then((res) => {
                    console.log(res.data.fileID);
                    if (res.data.status == "uploaded") {
                        newFile.url =
                            "https://lab-synapse.com/gql/storage?id=" +
                            res.data.fileID;
                        newFile.id = res.data.fileID;
                        newFile.uploaded = true;
                    } else {
                        this.files.splice(this.files.indexOf(newFile), 1);
                    }
                })
                .catch(() => {
                    this.files.splice(this.files.indexOf(newFile), 1);
                })
                .finally(() => {
                    e.target.value = null;
                });
        },
    },
    mounted() {
        if (this.tox_id) this.getFiles(this.tox_id);
    },
};
</script>