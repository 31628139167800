<template>
    <v-dialog width="400px" max-width="60vw" v-model="isOpened" persistent>
        <v-card>
            <v-toolbar dense dark flat color="primary">
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                    <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-title>{{text}}</v-card-title>
            <v-card-text>
                
                <v-row v-if="url">
                    <v-col align="center">
                        <v-img  :src="url" aspect-ratio="1" max-width="50%"></v-img>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="green darken-1" @click="decision(true)"><v-icon>mdi-check</v-icon>Yes</v-btn>
                <v-btn text color="gray" @click="decision(false)"><v-icon>mdi-close</v-icon>No</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Are you sure?"
        },
        text: {
            type: String,
            default: "Do you want to perform this action?"
        },
        url: String,
        value: Boolean,
        opened: Boolean,
        result: Function
    },
    data() {
        return {
            isOpened: false
        };
    },
    watch: {
        value(n) {
            if (this.isOpened != n) this.isOpened = n;
        }
    },
    methods: {
        decision(arg) {
            if (this.result instanceof Function) this.result(arg);
            this.isOpened = false;
            this.$emit("input", false);
        }
    },
    mounted() {
        this.isOpened = this.value;
    }
};
</script>