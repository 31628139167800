<template>
    <v-container fluid>
        <v-btn-toggle v-model="insType" mandatory tile>
            <v-btn v-for="i in insTypes" :key="i" text :value="i" width="120">
                {{i}}
                <span v-if="required[i]['label']" style="color:red">&nbsp;*</span>
            </v-btn>
        </v-btn-toggle>
        <v-card :loading="isLoading">
            <v-card-title>
                {{insType}} Details
                <span v-if="required[insType]['label']" style="color:red">&nbsp;*</span>
                <span
                    v-if="required[insType]['label'] && insType=='Guarantor'"
                    class="caption"
                >( Patient below the age of 19 )</span>
                <v-spacer></v-spacer>
                <v-btn small class="mr-12" @click="removeInsurance(insType)">
                    <v-icon>mdi-trash-can</v-icon>
                    Delete {{insType}}
                </v-btn>
            </v-card-title>

            <v-card-text v-if="insurances[insType]">
                <v-form v-model="validated[insType]" ref="theForm">
                    <v-row>
                        <v-col cols="12" sm="6" md="3" align="center" v-if="insType!='Guarantor'">
                            <autocomplete
                                :value="insurances[insType].company_id"
                                model="InsuranceCompany"
                                :items="items"
                                item-value="id"
                                item-text="name"
                                label="Search Insurance"
                                :disabled="!editable"
                                return-object
                                @input="updatePayer"
                                outlined
                                dense
                                placeholder=" "
                                :rules="rules[insType]['company_id']"
                                :class="required[insType]['company_id']"
                            ></autocomplete>
                            <v-text-field
                                label="Policy Number"
                                v-model="insurances[insType].policy_number"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                                :rules="rules[insType]['policy_number']"
                                :class="required[insType]['policy_number']"
                            ></v-text-field>
                            <v-text-field
                                label="Policy Group"
                                v-model="insurances[insType].policy_group"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                                :rules="rules[insType]['policy_group']"
                                :class="required[insType]['policy_group']"
                            ></v-text-field>
                            <v-text-field
                                label="Policy Name"
                                v-model="insurances[insType].group_name"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <dpinput
                                label="Effective Date"
                                v-model="insurances[insType].effective_date"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></dpinput>
                            <dpinput
                                label="Expiration Date"
                                v-model="insurances[insType].exp_date"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></dpinput>
                            <v-checkbox
                                label="WC or Accident"
                                v-model="insurances[insType].accident"
                                :disabled="!editable"
                                class="mt-2"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" align="center">
                            <v-combobox
                                label="Relationship"
                                :items="['Self','Parent','Child','Spouse','Other']"
                                v-model="insurances[insType].relationship"
                                :disabled="!editable"
                                v-on:change="relationshipChanged"
                                outlined
                                dense
                                clearable
                                placeholder=" "
                                :rules="rules[insType]['relationship']"
                                :class="required[insType]['relationship']"
                            ></v-combobox>
                            <v-text-field
                                label="First Name"
                                v-model="insurances[insType].ins_first_name"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <v-text-field
                                label="Middle Name"
                                v-model="insurances[insType].ins_middle_name"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <v-text-field
                                label="Last Name"
                                v-model="insurances[insType].ins_last_name"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <dob
                                label="Date of Birth"
                                v-model="insurances[insType].insured_dob"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></dob>
                            <v-combobox
                                label="Gender"
                                :items="$store.state.genders"
                                v-model="insurances[insType].insured_gender"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-combobox>
                            <v-text-field
                                v-if="insType!='Guarantor'"
                                label="SSN"
                                v-model="insurances[insType].insured_ssn"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" align="center">
                            <v-text-field
                                label="Address 1"
                                v-model="insurances[insType].insured_address1"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <v-text-field
                                label="Address 2"
                                v-model="insurances[insType].insured_address2"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <v-text-field
                                label="City"
                                v-model="insurances[insType].insured_city"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <v-combobox
                                label="State"
                                :items="$store.state.usStatesAbrv"
                                v-model="insurances[insType].insured_state"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-combobox>
                            <v-text-field
                                label="Zip"
                                v-model="insurances[insType].insured_zip"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                            <dpinput
                                v-if="insType!='Guarantor'"
                                label="Injury Date"
                                v-model="insurances[insType].injury_date"
                                :disabled="!editable || !insurances[insType].accident"
                                outlined
                                dense
                                placeholder=" "
                            ></dpinput>
                            <v-combobox
                                v-if="insType!='Guarantor'"
                                label="Injury State"
                                :items="$store.state.usStatesAbrv"
                                v-model="insurances[insType].injury_place"
                                :disabled="!editable || !insurances[insType].accident"
                                outlined
                                dense
                                placeholder=" "
                            ></v-combobox>
                            <v-text-field
                                v-if="insType=='Guarantor'"
                                label="SSN"
                                v-model="insurances[insType].insured_ssn"
                                :disabled="!editable"
                                outlined
                                dense
                                placeholder=" "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" :md="insType=='Guarantor'?6:3" align="center">
                            <v-card elevation="0">
                                <v-card-text>
                                    <v-carousel
                                        v-if="pictures[insType]"
                                        cycle
                                        :show-arrows="false"
                                        hide-delimiter-background
                                        light
                                        height="200"
                                    >
                                        <v-carousel-item
                                            v-for="(picture,i) in pictures[insType]"
                                            :key="i"
                                        >
                                            <v-img
                                                :src="'https://lab-synapse.com/gql/storage?id='+picture.id"
                                                aspect-ratio="1.5"
                                                contain
                                            >
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                            indeterminate
                                                            color="primary lighten-2"
                                                            size="50"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </v-carousel-item>
                                    </v-carousel>
                                    <v-img
                                        v-else
                                        src="../../assets/hc.png"
                                        aspect-ratio="1.5"
                                        contain
                                    ></v-img>
                                </v-card-text>
                            </v-card>

                            <v-btn
                                color="primary lighten-2"
                                @click="captureDialog=true"
                            >Capture Card</v-btn>
                            <br />
                            <br />
                            <v-btn small>Check Eligibility</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-dialog
                v-model="captureDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
            >
                <ImageCapture
                    :ocr="true"
                    cardType="Ins"
                    @dataExtracted="onDataExtracted"
                    @close-dialog="captureDialog=false"
                />
            </v-dialog>
            <v-dialog v-model="selectCompanyDialog" max-width="45vw">
                <v-card fluid>
                    <v-toolbar dark dense :elevation="0">
                        <v-btn icon small @click="selectCompanyDialog=false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Select Insurance Company</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-select
                            ref="company"
                            label="Insurance Comapny"
                            :items="companyMatches"
                            item-value="id"
                            item-text="name"
                            return-object
                            @input="updatePayer"
                            @change="selectCompanyDialog=false"
                        ></v-select>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>

        <v-row justify="space-around">
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.down()">
                    <v-icon>mdi-arrow-left-bold</v-icon>BACK
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.save()">
                    <v-icon>mdi-content-save</v-icon>Save & Close
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn
                    rounded
                    v-on:click="stepUp()"
                    :disabled="!stepAllowed"
                    :color="stepAllowed?'green':null"
                >
                    NEXT
                    <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import gql from "graphql-tag";
import dpinput from "../../components/DatePickerInput.vue";
import dob from "../../components/DOB.vue";
import autocomplete from "../../components/AutoComplete.vue";
import { copy } from "../../tools/copy";
import { mask } from "vue-the-mask";
import ImageCapture from "../../components/ImageCapture.vue";
export default {
    props: ["patient", "value", "step", "tox"],
    components: {
        dpinput,
        dob,
        autocomplete,
        ImageCapture,
    },
    directives: {
        mask,
    },
    data() {
        return {
            insType: "Primary",
            insTypes: ["Primary", "Secondary", "Tertiary", "Guarantor"],
            required: {
                Primary: {},
                Secondary: {},
                Tertiary: {},
                Guarantor: {},
            },
            rules: {
                Primary: {},
                Secondary: {},
                Tertiary: {},
                Guarantor: {},
            },

            isLoading: false,
            editable: true,
            validated: {
                Primary: null,
                Secondary: null,
                Tertiary: null,
                Guarantor: null,
            },
            stepAllowed: false,

            items: [],
            insurances: {
                Primary: {},
                Secondary: {},
                Tertiary: {},
                Guarantor: {},
            },
            insurances_copy: {
                Primary: {},
                Secondary: {},
                Tertiary: {},
                Guarantor: {},
            },
            pictures: {
                Primary: [],
                Secondary: [],
                Tertiary: [],
                Guarantor: [],
            },
            ins_docs: [],

            uploading_picture: false,
            lastInsured: {
                Primary: {},
                Secondary: {},
                Tertiary: {},
                Guarantor: {},
            },

            guarantor: {},
            gValidated: null,
            guarantor_editable: false,

            gzItems: [],
            gzLoading: null,
            gzSearch: null,

            gcItems: [],
            gcLoading: null,
            gcSearch: null,

            captureDialog: false,
            companyMatches: [],
            selectCompanyDialog: false,
            captured: {
                Primary: [],
                Secondary: [],
                Tertiary: [],
                Guarantor: [],
            },
            cacheIns: {},
        };
    },
    watch: {
        patient: {
            handler(n) {
                if (n.id) this.getInsurances(n.id);
                else {
                    this.insurances = {
                        Primary: {},
                        Secondary: {},
                        Tertiary: {},
                        Guarantor: {},
                    };
                    this.insurances_copy = JSON.parse(
                        JSON.stringify(this.insurances)
                    );
                }
                this.setRules();
            },
            deep: true,
            immediate: true,
        },

        insurances: {
            handler(n) {
                this.setRules();
                if (n !== this.value) this.$emit("input", n);
            },
            deep: true,
        },

        tox: {
            handler(n) {
                if (n.insurance_type == "Insurance") {
                    this.insTypes = [
                        "Primary",
                        "Secondary",
                        "Tertiary",
                        "Guarantor",
                    ];
                    this.insType = "Primary";
                } else if (n.insurance_type) {
                    this.insTypes = ["Guarantor"];
                    this.insType = "Guarantor";
                }
                this.setRules();
            },
            deep: true,
        },

        ins_type() {
            this.setRules();
            //if (this.$refs.theForm) this.$refs.theForm.resetValidation();
        },
    },
    computed: {
        insChanged() {
            return (
                JSON.stringify(this.insurances) !=
                JSON.stringify(this.insurances_copy)
            );
        },
    },
    methods: {
        b64ToBlob(data) {
            var byteString = atob(data);
            var u8arr = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
                u8arr[i] = byteString.charCodeAt(i);
            }
            return new Blob([u8arr], { type: "image/jpeg" });
        },
        uploadCapturedImages(record_id, ins_type) {
            var app = this;
            // Save Images
            for (var i = 0; i < this.captured[ins_type].length; i++) {
                let formData = new FormData();
                formData.append(
                    "file",
                    this.b64ToBlob(this.captured[ins_type][i]),
                    "card-" + i + ".jpg"
                );

                this.axios
                    .post(
                        "https://lab-synapse.com/gql/storage/?type=insurance&ownerType=patient_insurance&ownerID=" +
                            record_id,
                        formData
                    )
                    .then((res) => {
                        if (res.data.status == "uploaded") {
                            app.pictures[ins_type].push({
                                id: res.data.fileID,
                            });
                            app.captured[ins_type][i] = null;
                        } else alert("Uploading failed!");
                    });
            }
        },
        onDataExtracted(OCRData, cardFront, cardBack) {
            this.captureDialog = false;

            if (cardFront != null)
                this.captured[this.insTypes[this.insType]].push(cardFront);
            if (cardBack != null)
                this.captured[this.insTypes[this.insType]].push(cardBack);

            var app = this;
            if (OCRData.ErrorCode == null) {
                if (OCRData.MemberId)
                    this.insurances[this.insType].policy_number =
                        OCRData.MemberId;
                if (OCRData.GroupNumber)
                    this.insurances[this.insType].policy_group =
                        OCRData.GroupNumber;
                if (OCRData.FirstName)
                    this.insurances[this.insType].ins_first_name =
                        OCRData.FirstName;
                if (OCRData.MiddleName)
                    this.insurances[this.insType].ins_middle_name =
                        OCRData.MiddleName;
                if (OCRData.LastName)
                    this.insurances[this.insType].ins_last_name =
                        OCRData.LastName;
                if (OCRData.EffectiveDate)
                    this.insurances[this.insType].effective_date =
                        OCRData.EffectiveDate;
                if (OCRData.ExpirationDate)
                    this.insurances[this.insType].exp_date =
                        OCRData.ExpirationDate;
                if (OCRData.PlanProvider || OCRData.PayerId) {
                    let cond = [{ OR: [] }];
                    if (OCRData.PayerId)
                        cond[0]["OR"].push({ PID: { eq: OCRData.PayerId } });
                    if (OCRData.PlanProvider)
                        cond[0]["OR"].push({
                            name: { like: "%" + OCRData.PlanProvider + "%" },
                        });
                    this.$apollo
                        .query({
                            query: gql`
                                query ($wh: [InsuranceCompanyConditionAND]!) {
                                    InsuranceCompanys(where: $wh) {
                                        id
                                        name
                                    }
                                }
                            `,
                            variables: {
                                wh: cond,
                            },
                        })
                        .then((res) => {
                            for (
                                let i = 0;
                                i < res.data.InsuranceCompanys.length;
                                i++
                            ) {
                                app.companyMatches.push({
                                    id: res.data.InsuranceCompanys[i].id,
                                    name: res.data.InsuranceCompanys[i].name,
                                });
                            }
                            if (app.companyMatches.length > 0) {
                                app.selectCompanyDialog = true;
                                app.$refs.company.activateMenu();
                            }
                        });
                }
            } else {
                alert(
                    "Error Code: " +
                        OCRData.ErrorCode +
                        "\r\nError Description: " +
                        OCRData.ErrorDescription +
                        "\r\nContext: " +
                        OCRData.Context
                );
            }
        },
        updatePayer(val) {
            if (val.id)
                this.$set(this.insurances[this.insType], "company_id", val.id);

            if (val.id && val.name) {
                if (!this.items.some((i) => i.id == val.id)) {
                    this.items.push({ id: val.id, name: val.name });
                }
            }
        },

        /** Called when Relationship is changed
         * Sets insured fields = patient or revers them back
         */
        relationshipChanged() {
            if (this.insurances[this.insType].relationship == "Self") {
                this.lastInsured[this.insType] = copy(this.ins);
                this.insurances[this.insType].ins_first_name =
                    this.patient.first_name;
                this.insurances[this.insType].ins_last_name =
                    this.patient.last_name;
                this.insurances[this.insType].ins_middle_name =
                    this.patient.middle_name;
                this.insurances[this.insType].insured_dob = this.patient.dob;
                this.insurances[this.insType].insured_ssn = this.patient.ssn;
                this.insurances[this.insType].insured_gender =
                    this.patient.gender;
                this.insurances[this.insType].insured_address1 =
                    this.patient.address1;
                this.insurances[this.insType].insured_address2 =
                    this.patient.address2;
                this.insurances[this.insType].insured_city = this.patient.city;
                this.insurances[this.insType].insured_state =
                    this.patient.state;
                this.insurances[this.insType].insured_zip = this.patient.zip;
                this.insurances[this.insType].insured_phone =
                    this.patient.phone;
            } else {
                this.insurances[this.insType].ins_first_name =
                    this.lastInsured[this.insType].ins_first_name;
                this.insurances[this.insType].ins_last_name =
                    this.lastInsured[this.insType].ins_last_name;
                this.insurances[this.insType].ins_middle_name =
                    this.lastInsured[this.insType].ins_middle_name;
                this.insurances[this.insType].insured_dob =
                    this.lastInsured[this.insType].insured_dob;
                this.insurances[this.insType].insured_ssn =
                    this.lastInsured[this.insType].insured_ssn;
                this.insurances[this.insType].insured_gender =
                    this.lastInsured[this.insType].insured_gender;
                this.insurances[this.insType].insured_address1 =
                    this.lastInsured[this.insType].insured_address1;
                this.insurances[this.insType].insured_address2 =
                    this.lastInsured[this.insType].insured_address2;
                this.insurances[this.insType].insured_city =
                    this.lastInsured[this.insType].insured_city;
                this.insurances[this.insType].insured_state =
                    this.lastInsured[this.insType].insured_state;
                this.insurances[this.insType].insured_zip =
                    this.lastInsured[this.insType].insured_zip;
                this.insurances[this.insType].insured_phone =
                    this.lastInsured[this.insType].insured_phone;
            }
        },

        save() {
            if (!this.patient.id) return;
            this.isLoading = true;
            
            this.cacheIns[this.patient.id] = undefined;

            let insToSave = JSON.parse(JSON.stringify(this.insurances));
            let insurance = [];

            Object.keys(insToSave).forEach((k) => {
                insToSave[k]["InsuranceCompany"] = undefined;
                insToSave[k]["Files"] = undefined;
                insToSave[k]["__typename"] = undefined;
                insToSave[k]["deleted"] = undefined;
                insToSave[k]["deleted_by"] = undefined;
                let nonNullCount = Object.keys(insToSave[k]).reduce(
                    (acc, kk) => acc + (insToSave[k][kk] != null ? 1 : 0),
                    0
                );
                if (nonNullCount > 0)
                    insurance.push({
                        ...insToSave[k],
                        type: k,
                        patient_id: this.patient.id,
                    });
            });

            if (insurance.length < 1) return;

            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation ($insurance: [PatientInsuranceSave]!) {
                            PatientInsurancesSave(data: $insurance) {
                                id
                                patient_id
                                InsuranceCompany {
                                    name
                                }
                                Files {
                                    id
                                }
                                company_id
                                priority
                                policy_number
                                policy_group
                                group_name
                                effective_date
                                exp_date
                                relationship
                                ins_first_name
                                ins_last_name
                                ins_middle_name
                                insured_dob
                                insured_ssn
                                insured_gender
                                insured_address1
                                insured_address2
                                insured_city
                                insured_state
                                insured_zip
                                insured_phone
                                accident
                                injury_date
                                injury_place
                                deleted
                                deleted_by
                            }
                        }
                    `,
                    variables: {
                        insurance: insurance,
                    },
                })
                .then((res) => {
                    let data = {};
                    res.data.PatientInsurancesSave.forEach((v) => {
                        if (v instanceof Object && v.type) {
                            data[v.type] = [v];
                            this.uploadCapturedImages(v.id, v.type);
                        }
                    });
                    //this.saveToVar(data);
                    if (this.patient.id) this.getInsurances(this.patient.id);
                })
                .catch((err) => {
                    console.log(err);
                    if (this.patient.id) this.getInsurances(this.patient.id);
                });
        },

        getInsurances(id) {
            if (this.cacheIns[this.patient.id]) {
                this.saveToVar(this.cacheIns[this.patient.id]);
                return;
            }
            this.isLoading = true;
            this.$apollo
                .query({
                    query: gql`
                    query {
                        Primary: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { type: { eq: "Primary" } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                        Secondary: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { type: { eq: "Secondary" } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                        Tertiary: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { type: { eq: "Tertiary" } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                        Guarantor: PatientInsurances(
                            options: {
                                page: 1
                                itemsPerPage: 1
                                sortBy: [id]
                                sortDesc: [true]
                            }
                            where: [
                                { patient_id: { eq: "${id}" } }
                                { type: { eq: "Guarantor" } }
                                { deleted: { is: null } }
                            ]
                        ) {
                            ...insFields
                        }
                    }
                    fragment insFields on PatientInsurance {
                        id
                        patient_id
                        InsuranceCompany {
                            name
                        }
                        Files {
                            id
                        }
                        company_id
                        priority
                        policy_number
                        policy_group
                        group_name
                        effective_date
                        exp_date
                        relationship
                        ins_first_name
                        ins_last_name
                        ins_middle_name
                        insured_dob
                        insured_ssn
                        insured_gender
                        insured_address1
                        insured_address2
                        insured_city
                        insured_state
                        insured_zip
                        insured_phone
                        accident
                        injury_date
                        injury_place
                    }
                `,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (!this.cacheIns[this.patient.id]) {
                        this.cacheIns[this.patient.id] = res.data;
                    }
                    this.saveToVar(res.data);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        saveToVar(data) {
            this.insurances = {
                Primary: {},
                Secondary: {},
                Tertiary: {},
                Guarantor: {},
            };
            this.insurances_copy = JSON.parse(JSON.stringify(this.insurances));
            if (data.Primary && data.Primary[0] && !data.Primary[0].deleted) {
                this.insurances.Primary = {
                    ...data.Primary[0],
                    Files: undefined,
                };
                this.pictures.Primary = data.Primary[0].Files;
                if (data.Primary[0].company_id) {
                    this.items.push({
                        id: data.Primary[0].company_id,
                        name: data.Primary[0].InsuranceCompany
                            ? data.Primary[0].InsuranceCompany.name
                            : null,
                    });
                }
            }

            if (
                data.Secondary &&
                data.Secondary[0] &&
                !data.Secondary[0].deleted
            ) {
                this.insurances.Secondary = {
                    ...data.Secondary[0],
                    Files: undefined,
                };
                this.pictures.Secondary = data.Secondary[0].Files;
                this.items.push({
                    id: data.Secondary[0].company_id,
                    name: data.Secondary[0].InsuranceCompany
                        ? data.Secondary[0].InsuranceCompany.name
                        : null,
                });
            }

            if (
                data.Tertiary &&
                data.Tertiary[0] &&
                !data.Tertiary[0].deleted
            ) {
                this.insurances.Tertiary = {
                    ...data.Tertiary[0],
                    Files: undefined,
                };
                this.pictures.Tertiary = data.Tertiary[0].Files;
                this.items.push({
                    id: data.Tertiary[0].company_id,
                    name: data.Tertiary[0].InsuranceCompany
                        ? data.Tertiary[0].InsuranceCompany.name
                        : null,
                });
            }

            if (
                data.Guarantor &&
                data.Guarantor[0] &&
                !data.Guarantor[0].deleted
            ) {
                this.insurances.Guarantor = {
                    ...data.Guarantor[0],
                    Files: undefined,
                };
                this.pictures.Guarantor = data.Guarantor[0].Files;
            }

            if (this.insType) this.ins = this.insurances[this.insType];
            this.insurances_copy = JSON.parse(JSON.stringify(this.insurances));
        },

        removeInsurance(type) {
            let obj = this.insurances[type];

            if (obj.id) {
                obj["_delete"] = true;
                this.save();
            } else {
                this.$set(this.insurances, type, {});
            }

            if (this.$refs.theForm) this.$refs.theForm.resetValidation();
            if (this.$refs.theForm) this.$refs.theForm.validate();
        },

        stepUp() {
            if (this.insChanged) {
                this.save();
            }
            if (this.stepAllowed) this.step.up();
        },

        setRules() {
            if (!(this.insTypes instanceof Object)) return;
            if (!(this.rules instanceof Object)) return;
            if (!(this.required instanceof Object)) return;
            if (!(this.insTypes instanceof Array)) return;

            let notEmptyRule = (val) => !!val || "Required Field";

            /** Clears all rules */
            Object.keys(this.rules).forEach((k1) => {
                if (!this.rules[k1]) this.rules[k1] = {};
                Object.keys(this.rules[k1]).forEach((k2) => {
                    this.rules[k1][k2] = undefined;
                });
            });
            Object.keys(this.required).forEach((k1) => {
                if (!this.required[k1]) this.required[k1] = {};
                Object.keys(this.required[k1]).forEach((k2) => {
                    this.required[k1][k2] = undefined;
                });
            });

            /** Calculates rules just for those Ins Types being present */
            this.insTypes.forEach((iType) => {
                let hasVal = false;
                if (this.insurances && this.insurances[iType]) {
                    hasVal = Object.values(this.insurances[iType]).some(
                        (v) => !!v
                    );
                }
                if (iType == "Guarantor" && this.patient?.dob) {
                    let dob = new Date(this.patient.dob);
                    let age = (new Date() - dob) / 31536000000;
                    if (age < 19 || hasVal) {
                        this.rules[iType].relationship = [notEmptyRule];

                        this.required[iType].label = age < 19;
                        this.required[iType].relationship = "required";
                    }
                } else if (iType == "Primary" || hasVal) {
                    this.rules[iType].company_id = [notEmptyRule];
                    this.rules[iType].policy_number = [notEmptyRule];
                    this.rules[iType].relationship = [notEmptyRule];

                    this.required[iType].label = iType == "Primary";
                    this.required[iType].company_id = "required";
                    this.required[iType].policy_number = "required";
                    this.required[iType].relationship = "required";
                }
            });

            /** Evaluates rules just for those Ins Types being present */
            this.stepAllowed = this.insTypes.reduce((acc1, iType) => {
                if (
                    !(this.rules[iType] instanceof Object) ||
                    !(this.insurances[iType] instanceof Object)
                ) {
                    return false;
                }
                return (
                    acc1 &&
                    Object.keys(this.rules[iType]).reduce((acc2, k) => {
                        if (!(this.rules[iType][k] instanceof Array)) {
                            return true;
                        }
                        return (
                            acc2 &&
                            this.rules[iType][k].reduce((acc3, fn) => {
                                return (
                                    acc3 &&
                                    fn(this.insurances[iType][k]) === true
                                );
                            }, true)
                        );
                    }, true)
                );
            }, true);
        },
    },
};
</script>