<template>
    <v-card :loading="loadingSignature" height="100%">
        <v-card-title>
            {{title}}&nbsp;
            <span style="color:red" v-if="required">*</span>
            <v-spacer />
            <v-btn icon @click="delSignature(signature.id)">
                <v-icon>mdi-trash-can</v-icon>
            </v-btn>
        </v-card-title>

        <v-img
            v-if="signature && signature.src && !signature.error"
            :src="signature.src"
            @error="signature.error=true"
            aspect-ratio="4"
            contain
        >
            <template v-slot:placeholder>
                <v-progress-circular indeterminate class="ma-auto"></v-progress-circular>
            </template>
        </v-img>
        <v-card-text v-else-if="signature && signature.src">
            <v-btn x-large outlined width="100%" @click="showPicture()">
                <v-icon>mdi-paperclip</v-icon>PDF
            </v-btn>
            <br />
        </v-card-text>

        <v-dialog v-model="signDialog" width="600">
            <SignPad @input="saveSignature" @close="closeSignatureDialog()"></SignPad>
        </v-dialog>
        <v-dialog v-model="pictureDialog.opened" width="900">
            <v-card :loading="pictureDialog.loading" height="80vh">
                <embed :src="pictureDialog.url" width="99%" height="99%" />
            </v-card>
        </v-dialog>
        <input type="file" name="file" style="visibility: hidden;" ref="fileInput" />

        <v-card-actions>
            <v-btn outlined @click="openSignatureDialog()">
                <v-icon>mdi-draw</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn v-if="user_signature" outlined @click="setSavedSignature()">
                <v-icon>mdi-content-save-edit-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn outlined @click="selectFileToUpload()">
                <v-icon>mdi-paperclip</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import SignPad from "../../components/SignPad.vue";
import gql from "graphql-tag";
export default {
    props: {
        title: String,
        required: {
            type: [Boolean, String],
            default: false,
        },
        type: [String],
        id: [String, Number],
        user: [String, Number],
    },
    components: {
        SignPad,
    },
    data() {
        return {
            signDialog: false,
            signature: { id: null, src: null, error: null },
            loadingSignature: false,
            pictureDialog: { opened: false, url: null, loading: false },
            user_signature: null,
        };
    },
    methods: {
        async setSavedSignature() {
            this.loadingSignature = true;
            if (this.user_signature?.id) {
                let res = await this.axios.get(
                    `https://lab-synapse.com/gql/storage/?do=copy&fromID=${this.user_signature.id}&type=${this.type}&ownerType=tox&ownerID=${this.id}`
                );
                if (res.data.status == "uploaded") {
                    this.signature = {
                        id: res.data.fileID,
                        src: `https://lab-synapse.com/gql/storage?id=${res.data.fileID}`,
                        error: null,
                    };
                }
            }
            this.loadingSignature = false;
        },
        showPicture() {
            this.pictureDialog = { opened: true, url: this.signature.src };
        },
        openSignatureDialog() {
            this.signDialog = true;
        },
        closeSignatureDialog() {
            this.signDialog = false;
        },
        delSignature(id) {
            if (!id) return;
            this.loadingSignature = true;
            this.$apollo
                .mutate({
                    mutation: gql`
                            mutation {
                                FileDelete(where: [{ id: { eq: "${id}" } }]) {
                                    id
                                }
                            }
                        `,
                })
                .then((res) => {
                    if (res.data.FileDelete instanceof Array) {
                        res.data.FileDelete.forEach((v) => {
                            if (this.signature.id == v.id) {
                                this.signature = {
                                    id: null,
                                    src: null,
                                    error: null,
                                };
                            }
                        });
                    }
                })
                .finally(() => {
                    this.loadingSignature = false;
                });
        },
        saveSignature(data) {
            if (data && this.type && this.id) {
                this.loadingSignature = true;
                fetch(data)
                    .then((res) => res.blob())
                    .then((blob) =>
                        this.uploadFile(
                            blob,
                            `EreqSignature${this.id}.png`,
                            this.type,
                            "tox",
                            this.id
                        )
                    )
                    .then((res) => {
                        this.signature = res;
                        this.signDialog = false;
                    })
                    .catch((error) => alert(error))
                    .finally(() => {
                        this.loadingSignature = false;
                    });
            }
        },
        async uploadFile(blob, fileName, type, ownerType, ownerID) {
            const formData = new FormData();
            formData.append("file", blob, fileName);
            let res = null;

            res = await this.axios.post(
                `https://lab-synapse.com/gql/storage/?type=${type}&ownerType=${ownerType}&ownerID=${ownerID}`,
                formData
            );

            if (res.data.status == "uploaded") {
                return {
                    id: res.data.fileID,
                    src: `https://lab-synapse.com/gql/storage?id=${res.data.fileID}`,
                    error: null,
                };
            } else throw new Error(res.data.error);
        },
        async getSignature(type, owner_type, owner_id) {
            try {
                this.loadingSignature = true;
                let res = await this.$apollo.query({
                    query: gql`
                        query ($where: [FileConditionAND]) {
                            Files(
                                where: $where
                                options: {
                                    sortBy: id
                                    sortDesc: true
                                    itemsPerPage: 1
                                    page: 1
                                }
                            ) {
                                id
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        where: [
                            { type: { eq: type } },
                            { owner_type: { eq: owner_type } },
                            { owner_id: { eq: owner_id } },
                            { deleted: { is: null } },
                            { status: { eq: "OK" } },
                        ],
                    },
                });
                if (res.data.Files instanceof Array) {
                    if (res.data.Files[0]) {
                        return {
                            ...res.data.Files[0],
                            src: `https://lab-synapse.com/gql/storage?id=${res.data.Files[0]?.id}`,
                            error: null,
                        };
                    }
                }
            } finally {
                this.loadingSignature = false;
            }
        },
        selectFileToUpload() {
            this.$refs.fileInput.value = null;
            //this.$refs.fileInput.setAttribute("img-type", type);
            this.$refs.fileInput.accept = ".gif,.jpg,.jpeg,.bmp,.svg,.pdf";
            this.$refs.fileInput.click();

            this.$refs.fileInput.removeEventListener(
                "change",
                this._uploadFile
            );
            this.$refs.fileInput.addEventListener("change", this._uploadFile);
        },
        _uploadFile(e) {
            if (e.target.files && this.type && this.id) {
                this.loadingSignature = true;
                const file = e.target.files[0];

                this.uploadFile(
                    file,
                    `EreqSignature${this.id}.png`,
                    this.type,
                    "tox",
                    this.id
                )
                    .then((res) => {
                        this.signature = res;
                        this.signDialog = false;
                    })
                    .catch((error) => alert(error))
                    .finally(() => {
                        this.loadingSignature = false;
                    });
            }
        },
    },
    watch: {
        user() {
            if (this.user)
                this.getSignature("signature", "users", this.user).then(
                    (res) => (this.user_signature = res)
                );
        },
        type() {
            if (this.type && this.id)
                this.getSignature(this.type, "tox", this.id).then(
                    (res) => (this.signature = res)
                );
        },
        id() {
            if (this.type && this.id)
                this.getSignature(this.type, "tox", this.id).then(
                    (res) => (this.signature = res)
                );
        },
        signature: {
            handler() {
                this.$emit("input", this.signature?.id);
            },
            deep: true,
        },
        /*value(n) {
            if (n) {
                this.signature = {
                    id: n,
                    src: `https://lab-synapse.com/gql/storage?id=${n}`,
                    error: null,
                };
            } else {
                this.signature = {
                    id: null,
                    src: null,
                    error: null,
                };
            }
        },*/
    },
    mounted() {
        if (this.type && this.id)
            this.getSignature(this.type, "tox", this.id).then(
                (res) => (this.signature = res)
            );
        if (this.user)
            this.getSignature("signature", "users", this.user).then(
                (res) => (this.user_signature = res)
            );
    },
};
</script>