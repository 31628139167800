<template>
    <v-container class="py-0" fluid>
        <v-row>
            <v-col cols="12" md="4">
                <v-card height="100%">
                    <v-card-title>Laboratories</v-card-title>

                    <v-card-text style="height:60vh; overflow-y: scroll;">
                        <v-list two-line dense subheader>
                            <v-list-item-group multiple v-model="selectedLabIDs">
                                <v-list-item v-for="lab in labs" :key="lab.id" :value="lab.id">
                                    <v-list-item-action>
                                        <v-checkbox
                                            readonly
                                            color="primary"
                                            v-model="selectedLabIDs"
                                            :value="lab.id"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong>{{lab.name}}</strong>
                                        </v-list-item-title>
                                        <v-list-item-subtitle
                                            v-if="lab.inNetwork"
                                            style="color:#69f0ae;"
                                        >In Network</v-list-item-subtitle>
                                        <v-list-item-subtitle v-else style="color:red;">
                                            Out of
                                            Network
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            <v-rating
                                                dense
                                                x-small
                                                half-increments
                                                color="primary"
                                                :value="lab.rank/100*6"
                                            ></v-rating>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn
                                            :to="'../facility/'+lab.id"
                                            icon
                                            color="primary"
                                            target="_blank"
                                        >
                                            <v-icon>mdi-information-outline</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="8">
                <v-card height="100%">
                    <v-card-title>Laboratory Tests</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    label="Select Test"
                                    :items="labLests"
                                    item-text="name"
                                    item-value="id"
                                    no-filter
                                    :search-input.sync="searchCode"
                                    v-model="selectedCode"
                                    no-data-text="Search Test Code"
                                >
                                    <template v-slot:item="{item}">
                                        <v-list-item-content @click="clicked(item)">
                                            <v-list-item-title>{{item.order_name}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.group_name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col>
                                <model
                                    model="UserToxPanel"
                                    :conditions="[{deleted:{is:null}}]"
                                    src="id,name,Map{order_code_id,OrderCode{facility_id}}"
                                    :options="{page:1, itemsPerPage:1000}"
                                    :autoload="true"
                                >
                                    <template v-slot="{data,loading}">
                                        <v-select
                                            label="User Panels"
                                            :items="data"
                                            item-text="name"
                                            item-value="id"
                                            :loading="loading"
                                            v-model="panelSelected"
                                            return-object
                                            multiple
                                            
                                            chips small-chips
                                        ></v-select>
                                    </template>
                                </model>
                            </v-col>
                        </v-row>

                        <v-data-table :items="selectedTests" :headers="codesHeader">
                            <template v-slot:item.delete="{item}">
                                <v-btn icon small @click="remCode(item.id)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>

                        <!--<v-row>
                            <v-col cols="6">
                                <h4>Select Category</h4>
                                <v-treeview
                                    dense
                                    selectable
                                    open-on-click
                                    :open="selectedTestIDs"
                                    v-model="selectedTestIDs"
                                    :items="groupedOrderCodes"
                                ></v-treeview>
                            </v-col>
                            <v-col>
                                
    

                                <v-row dense>
                                    <v-col align-self="center">
                                        <h3>ALL</h3>
                                    </v-col>
                                    <v-col v-for="i in 4" :key="i">
                                        <autocomplete
                                            model="Diagnosis"
                                            item-value="icd10_raw"
                                            item-text="name"
                                            label="Diagnosis"
                                            hide-details="auto"
                                            outlined
                                            dense
                                            clearable
                                            placeholder=" "
                                            return-object
                                            v-model="diagnosis[i]"
                                        ></autocomplete>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row dense v-for="(cpt, ckey) in validCPTs" :key="'ck_'+ckey">
                                    <v-col cols="2" align-self="center">
                                        <h3>{{cpt.cpt}}</h3>
                                    </v-col>
                                    <v-col v-for="i in 4" :key="i">
                                        <autocomplete
                                            model="Diagnosis"
                                            item-value="icd10_raw"
                                            item-text="name"
                                            label="Diagnosis"
                                            hide-details="auto"
                                            outlined
                                            dense
                                            clearable
                                            return-object
                                            placeholder=" "
                                            v-model="cpt.diagnosis[i]"
                                        ></autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>-->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.down()">
                    <v-icon>mdi-arrow-left-bold</v-icon>BACK
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.save()">
                    <v-icon>mdi-content-save</v-icon>Save & Close
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <v-btn
                    rounded
                    v-on:click="step.up()"
                    :disabled="!stepAllowed"
                    :color="stepAllowed?'green':null"
                >
                    NEXT
                    <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import gql from "graphql-tag";
import Model from "../../components/Model.vue";
//import autocomplete from "../../components/AutoComplete.vue";
//import datepicker from "../../components/DatePickerInput";
//import timepicker from "../../components/TimePickerInput";
export default {
    props: [
        "insurances",
        "step",
        "facility_id",
        "ordered_codes",
        "selected_labs",
        "billing_type",
    ],
    components: { Model /*autocomplete, datepicker, timepicker*/ },
    data() {
        return {
            panelSelected: [],
            panelMutex: false,

            orderCodes: [], // Filled in when lab is selected. Contains Order code's objects
            selectedTestIDs: [],

            test_type: null,
            tests: [],

            panels: [],
            all_tests_performed: false,
            tests_not_performed: [],

            selectedLabIDs: [], // contains selected lab's ids
            labs: [], // loaded by getLabs(), contains labs to select from
            codeGroups: [], // loaded by getLabs(), contains code Groups

            labMap: {},
            insID: null,

            //----------------------------

            cptTestCodeMap: {},
            testMap: {},
            cptMap: {},
            cptTestMap: {},

            unmappedCodes: [],
            mappedTests: [],
            mappedCPTs: [],
            unmappedTests: [],
            foundCPTs: [],
            missingCPTs: [],

            //--------------------------------

            diagnosis: { 1: null, 2: null, 3: null, 4: null },

            //-----------------------------------------------

            searchCode: null,
            selectedCode: null,
            codesHeader: [
                { value: "order_name", text: "Code" },
                { value: "group_name", text: "Group" },
                { value: "required_volume", text: "Vol" },
                { value: "Container.name", text: "Container" },
                { value: "delete", text: "Del" },
            ],
        };
    },
    watch: {
        selectedTestIDs(n) {
            if (this.panelMutex) {
                this.panelMutex = false;
                return;
            }

            this.panelSelected = this.panelSelected.filter((p) => {
                return p.Map?.map((vv) => vv.order_code_id).reduce(
                    (acc, val) => acc && n.indexOf(val) >= 0,
                    true
                );
            });

            this.panelMutex = true;
        },
        panelSelected(n, o) {
            if (this.panelMutex) {
                this.panelMutex = false;
                return;
            }
            n.forEach((v) => {
                if (v.Map instanceof Array) {
                    v.Map.forEach((m) => {
                        let f = m.OrderCode?.facility_id;
                        if (f && this.selectedLabIDs.indexOf(f) < 0) {
                            //if (confirm("Do you want to enable this lab?")) {
                            this.selectedLabIDs.push(f);
                            //}
                        }
                    });
                }
            });

            let ncodes = n.reduce((acc, v) => {
                if (v.Map instanceof Array) {
                    return [...acc, ...v.Map.map((vv) => vv.order_code_id)];
                } else return acc;
            }, []);
            let ocodes = o.reduce((acc, v) => {
                if (v.Map instanceof Array) {
                    return [...acc, ...v.Map.map((vv) => vv.order_code_id)];
                } else return acc;
            }, []);

            this.selectedTestIDs = this.selectedTestIDs.filter(
                (s) => ocodes.indexOf(s) < 0
            );

            this.selectedTestIDs = [...this.selectedTestIDs, ...ncodes];

            this.panelMutex = true;
        },
        insurances: {
            handler(n) {
                if (n?.Primary?.company_id != this.insID) {
                    this.insID = n?.Primary?.company_id;
                    this.getLabs();
                }
            },
            deep: true,
        },
        billing_type: {
            handler() {
                this.getLabs();
            },
        },
        /** Triggered when lab is selected. Fills in this.orderCodes*/
        selectedLabs(n) {
            this.orderCodes = [];

            n.forEach((v) => {
                this.orderCodes = this.orderCodes.concat(v.LabOrderCodes);
            });
        },
        selectedTestsNoGroups() {
            this.mapTests();
            this.mapCPTs();

            this.$emit("input", {
                tests: this.selectedTestsByContainersAndLabs,
                cpts: this.validCPTs,
            });
        },
        selectedCode(n) {
            if (n != null) {
                if (this.selectedTestIDs.indexOf(n) < 0)
                    this.selectedTestIDs.push(n);
                setTimeout(() => {
                    this.selectedCode = null;
                }, 10);
            }
        },
    },
    methods: {
        /** GraphQL query to load labs , tests and test groups */
        getLabs() {
            let subscribed = null;
            //"Pre-Paid", "Self Pay", "Client Pay"
            if (this.billing_type == "Insurance") subscribed = this.insID;
            if (this.billing_type == "Self Pay") subscribed = 1;
            if (this.billing_type == "Pre-Paid") subscribed = 2;
            if (this.billing_type == "Client Pay") subscribed = 3;

            if (!subscribed) return;

            this.isLoading = true;

            this.$apollo
                .query({
                    query: gql`
                        query (
                            $whereLab: [LaboratoryConditionAND]
                            $wherePayers: [FacilityPayerConditionAND]
                            $settingsWhere: [facilitySettingConditionAND]
                            $labOrderCodeGroupsWhere: [LabOrderCodeGroupConditionAND]
                            $labOrderCodesWhere: [LabOrderCodeConditionAND]
                        ) {
                            Laboratorys(where: $whereLab) {
                                id
                                name
                                rank
                                LabOrderCodes(where: $labOrderCodesWhere) {
                                    id
                                    order_code
                                    order_name
                                    order_abrv
                                    required_volume
                                    group_id
                                    group_name
                                    group_parent_id
                                    facility_id

                                    Type {
                                        id
                                        name
                                        prefix
                                    }
                                    Container {
                                        id
                                        name
                                        volume
                                        sample_name
                                        sample_id
                                    }
                                }

                                Payers(where: $wherePayers) {
                                    id
                                    company_id
                                    subscribed
                                    in_network
                                }

                                LabOrderCodeGroups(
                                    where: $labOrderCodeGroupsWhere
                                ) {
                                    id
                                    name
                                    parent_id
                                }

                                Settings(where: $settingsWhere) {
                                    type
                                    value
                                }
                            }
                        }
                    `,
                    fetchPolicy: "network-only",
                    variables: {
                        wherePayers: [
                            { company_id: { eq: subscribed } },
                            { deleted: { is: null } },
                        ],
                        whereLab: [
                            { deleted: { is: null } },
                            { subscribed: { eq: subscribed } },
                            { role: { eq: "Receiver" } },
                        ],
                        labOrderCodeGroupsWhere: [{ deleted: { is: null } }],
                        labOrderCodesWhere: [{ deleted: { is: null } }],
                        settingsWhere: [
                            {
                                type: {
                                    in: [
                                        "PatientSignatureRequire",
                                        "PhysicianSignatureRequire",
                                        "Disclaimer",
                                    ],
                                },
                            },
                            { deleted: { is: null } },
                        ],
                    },
                })
                .then((res) => {
                    if (res.data.Laboratorys instanceof Array) {
                        this.labs = res.data.Laboratorys;
                        this.labs.forEach((v) => {
                            if (v.Payers instanceof Array) {
                                v.inNetwork = v.Payers[0]?.in_network == 1;
                                v.score = v.rank - 0 + v.inNetwork * 100;
                            }

                            if (v.LabOrderCodeGroups instanceof Array) {
                                this.codeGroups = this.codeGroups.concat(
                                    v.LabOrderCodeGroups
                                );
                            }

                            if (v.Settings instanceof Array) {
                                v.Settings = v.Settings.reduce((acc, val) => {
                                    acc[val.type] = val.value;
                                    return acc;
                                }, {});
                            } else if (v.Settings == null) v.Settings = {};

                            this.labMap[v.id] = v;
                        });

                        this.getTestMap(res.data.Laboratorys.map((v) => v.id));
                        this.labs.sort((a, b) => b.score - a.score);
                        if (this.selected_labs)
                            // If saved set
                            this.selectedLabIDs = this.selected_labs;
                        if (this.ordered_codes)
                            this.selectedTestIDs = this.ordered_codes;
                    }
                })
                .finally(() => {});
        },
        /** Used by groupedOrderCodes() */
        activateBranchRecursion(tree, nodeID) {
            if (tree[nodeID]) {
                tree[nodeID]["active"] = true;
                if (tree[nodeID]["group_parent_id"])
                    this.activateBranchRecursion(
                        tree,
                        tree[nodeID]["group_parent_id"]
                    );
            }
        },
        /** Used by groupedOrderCodes() */
        clearBranchRecursion(branches) {
            if (branches instanceof Array) {
                for (let k = branches.length - 1; k >= 0; k--) {
                    let v = branches[k];
                    if (v.isGroup && v.active === undefined)
                        branches.splice(k, 1);
                    else if (v.children instanceof Array)
                        this.clearBranchRecursion(v.children);
                }
            }
        },

        //------------------------------------------
        getTestMap(facilities) {
            this.$apollo
                .query({
                    query: gql`{
                        CptTestCodeMaps(where:[{facility_id:{in:[${facilities}]}},{deleted:{is:null}}]) {
                            id
                            code_id
                            test_id
                        }
                        CptMaps(where:[{facility_id:{in:[${facilities}]}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            payer_group_id
                            priority
                            type
                            cpt
                            description
                            price
                            count_from
                            count_to
                            active_from
                            active_to
                        }
                        CptTestMaps(where:[{facility_id:{in:[${facilities}]}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            test_id
                            cpt_id
                            deleted
                        }
                        CptTests(where:[{facility_id:{in:[${facilities}]}},{deleted:{is:null}}]) {
                            id
                            facility_id
                            name
                            group_id
                            group_name
                        }
                    }`,
                    fetchPolicy: "no-cache",
                })
                .then((res) => {
                    if (res.data.CptTestCodeMaps instanceof Array) {
                        res.data.CptTestCodeMaps.forEach((v) => {
                            if (this.cptTestCodeMap[v.code_id] === undefined)
                                this.cptTestCodeMap[v.code_id] = [];
                            this.cptTestCodeMap[v.code_id].push(v.test_id);
                        });
                    }

                    if (res.data.CptMaps instanceof Array) {
                        res.data.CptMaps.forEach((v) => {
                            this.cptMap[v.id] = v;
                        });
                    }

                    if (res.data.CptTestMaps instanceof Array) {
                        res.data.CptTestMaps.forEach((v) => {
                            if (this.cptTestMap[v.test_id] === undefined)
                                this.cptTestMap[v.test_id] = [];
                            this.cptTestMap[v.test_id].push(v);
                        });
                    }

                    if (res.data.CptTests instanceof Array) {
                        res.data.CptTests.forEach((v) => {
                            this.testMap[v.id] = v;
                        });
                    }
                });
        },
        mapTests() {
            let temp = [];

            this.mappedTests = [];
            this.unmappedCodes = [];

            this.selectedTestsNoGroups.forEach((v) => {
                if (this.cptTestCodeMap[v.id] instanceof Array) {
                    //Aggregate tests - remove duplicates
                    this.cptTestCodeMap[v.id].forEach((vv) => {
                        if (temp[vv] == undefined) temp[vv] = [];
                        temp[vv].push(v);
                    });
                } else {
                    this.unmappedCodes.push(v);
                }
            });

            Object.keys(temp).forEach((k) => {
                let v = temp[k];
                this.mappedTests.push({ ...this.testMap[k], codes: v });
            });
        },
        mapCPTs() {
            let temp = {};
            let cpts = [];
            this.mappedCPTs = [];
            this.mappedTests.forEach((v) => {
                if (this.cptTestMap[v.id] instanceof Array) {
                    //Aggregate tests - remove duplicates
                    this.cptTestMap[v.id].forEach((vv) => {
                        if (temp[vv.cpt_id] === undefined) temp[vv.cpt_id] = [];
                        temp[vv.cpt_id].push(v);
                    });
                } else {
                    this.unmappedTests.push(v);
                }
            });
            //converts temp obj to array of cpt objects and sorts descending
            Object.keys(temp).forEach((k) => {
                if (this.cptMap[k] === undefined) this.missingCPTs.push(k);
                else {
                    let groups = {};
                    let grs = []; // Generate test groups
                    let tIDs = {};
                    let gIDs = {};
                    temp[k].forEach((vv) => {
                        if (groups[vv.group_id] === undefined) {
                            groups[vv.group_id] = {
                                id: vv.group_id,
                                name: vv.group_name,
                                tests: [],
                            };
                        }
                        groups[vv.group_id].tests.push(vv);
                        if (gIDs[vv.group_id] === undefined)
                            gIDs[vv.group_id] = {};
                        gIDs[vv.group_id][vv.id] = true;
                        tIDs[vv.id] = true;
                    });

                    grs = Object.keys(groups).map((k) => groups[k]);

                    cpts.push({
                        ...this.cptMap[k],
                        groups: grs,
                        tests: temp[k],
                        tIDs,
                        gIDs,
                    });
                }
            });
            cpts.sort((a, b) => b.priority - a.priority);

            cpts.forEach((v, k) => {
                let len = -1;
                if (v.type == "test_based") {
                    len = Object.keys(v.tIDs).length;
                }
                if (v.type == "group_based") {
                    len = Object.keys(v.gIDs).length;
                }
                v.valid = v.count_from <= len && len <= v.count_to;

                if (v.valid) {
                    for (let i = k + 1; i < cpts.length; i++) {
                        Object.keys(v.tIDs).forEach((tid) => {
                            if (cpts[i].tIDs[tid]) delete cpts[i].tIDs[tid];

                            Object.keys(cpts[i].gIDs).forEach((kk) => {
                                if (cpts[i].gIDs[kk]) {
                                    if (cpts[i].gIDs[kk][tid] !== undefined)
                                        delete cpts[i].gIDs[kk][tid];

                                    if (
                                        Object.keys(cpts[i].gIDs[kk]).length <=
                                        0
                                    )
                                        delete cpts[i].gIDs[kk];
                                }
                            });
                        });
                    }
                }
            });
            this.mappedCPTs = cpts;
        },
        //------------------------------------------
        getTestIDs(tests) {
            const tIDs = new Set();

            if (tests instanceof Array) {
                tests.forEach((test) => {
                    if (test.codes instanceof Array) {
                        test.codes.forEach((code) => {
                            tIDs.add(code.id);
                        });
                    }
                });
            }

            return Array.from(tIDs);
        },
        clicked(i) {
            if (
                i.notSelected &&
                this.selectedLabIDs.indexOf(i.facility_id) < 0
            ) {
                if (confirm("Do you want to enable this lab?")) {
                    this.selectedLabIDs.push(i.facility_id);
                }
            }
        },
        remCode(id) {
            let pos = this.selectedTestIDs.indexOf(id);
            if (pos >= 0) this.selectedTestIDs.splice(pos, 1);
        },
    },
    computed: {
        stepAllowed() {
            return this.selectedTests?.length > 0;
        },
        labLests() {
            let codes1 = [];
            let codes2 = [];
            let regexp = new RegExp(this.searchCode, "i");
            this.labs.forEach((v) => {
                let temp = [];
                if (this.selectedLabIDs.indexOf(v.id) >= 0) {
                    let temp = [];
                    v.LabOrderCodes.forEach((c) => {
                        if (
                            this.searchCode == null ||
                            c.order_name.search(regexp) >= 0
                        ) {
                            temp.push({ ...c, name: c.order_name });
                        }
                    });
                    if (temp.length > 0) {
                        codes1.push({ header: "Laboratory: " + v.name });
                        codes1.push({ divider: true });
                        codes1 = [...codes1, ...temp];
                    }
                } else {
                    v.LabOrderCodes.forEach((c) => {
                        if (
                            this.searchCode == null ||
                            c.order_name.search(regexp) >= 0
                        ) {
                            temp.push({
                                ...c,
                                name: c.order_name,
                                notSelected: true,
                            });
                        }
                    });
                    if (temp.length > 0) {
                        codes2.push({ header: "Laboratory: " + v.name });
                        codes2.push({ divider: true });
                        codes2 = [...codes2, ...temp];
                    }
                }
            });
            return [...codes1, ...codes2];
        },
        /** Calculated Grouped Order codes based on this.orderCodes */
        groupedOrderCodes() {
            let groupsMap = {};
            let rootGroups = [];
            let subGroups = [];

            this.codeGroups.forEach((v) => {
                let obj = {
                    id: "G" + v.id,
                    name: v.name,
                    group_id: v.id,
                    group_parent_id: v.parent_id,
                    isGroup: true,
                };
                groupsMap[v.id] = obj;

                if (v.parent_id == null) rootGroups.push(obj);
                else subGroups.push(obj);
            });

            subGroups.forEach((v) => {
                if (groupsMap[v.group_parent_id] === undefined)
                    groupsMap[v.group_parent_id] = {};
                if (groupsMap[v.group_parent_id]["children"] === undefined)
                    groupsMap[v.group_parent_id]["children"] = [];

                groupsMap[v.group_parent_id]["children"].push(v);
                //groupsMap[v.group_id]["parent"]=groupsMap[v.group_parent_id];
            });

            this.orderCodes.forEach((v) => {
                if (groupsMap[v.group_id] === undefined)
                    groupsMap[v.group_id] = {};
                if (groupsMap[v.group_id]["children"] === undefined)
                    groupsMap[v.group_id]["children"] = [];

                this.activateBranchRecursion(groupsMap, v.group_id);
                groupsMap[v.group_id]["children"].push({
                    id: v.id,
                    name: "(" + v.order_code + ") " + v.order_name,
                    ...v,
                });
            });

            this.clearBranchRecursion(rootGroups);

            return rootGroups;
        },
        /** Removes Grops from selection */
        selectedTestsByContainersAndLabs() {
            let noGroups = this.selectedTests.filter((v) => !v.isGroup);

            let res = [];
            let temp = {};
            let cMap = {};
            let tMap = {};
            noGroups.forEach((v) => {
                if (temp[v.facility_id] === undefined) temp[v.facility_id] = {};
                if (temp[v.facility_id][v.Container.id] === undefined)
                    temp[v.facility_id][v.Container.id] = [];
                temp[v.facility_id][v.Container.id].push({
                    id: v.id,
                    name: v.name,
                    order_code: v.order_code,
                    abrv: v.order_abrv,
                    required_volume: v.required_volume,
                });
                cMap[v.Container.id] = {
                    id: v.Container.id,
                    name: v.Container.name,
                    volume: v.Container.volume,
                    sample_id: v.Container.sample_id,
                    sample_name: v.Container.sample_name,
                };

                if (tMap[v.facility_id] === undefined) tMap[v.facility_id] = [];
                tMap[v.facility_id].push(v);
            });

            Object.keys(temp).forEach((k) => {
                let containers = [];
                Object.keys(temp[k]).forEach((kk) => {
                    let maxVolume = cMap[kk].volume;
                    let pass2 = [];
                    let flasks = [];
                    temp[k][kk].forEach((test) => {
                        if (test.required_volume == maxVolume) {
                            flasks.push({
                                left: 0,
                                tests: [test],
                            });
                        } else if (test.required_volume > maxVolume) {
                            let leftVol = test.required_volume;
                            while (leftVol >= 0) {
                                if (leftVol < maxVolume) {
                                    let placed = false;
                                    flasks.forEach((c) => {
                                        if (c.left >= leftVol && !placed) {
                                            c.left -= leftVol;
                                            c.tests.push(test);
                                            placed = true;
                                        }
                                    });
                                    if (!placed) {
                                        flasks.push({
                                            left: maxVolume - leftVol,
                                            tests: [test],
                                        });
                                    }
                                } else {
                                    flasks.push({
                                        left:
                                            maxVolume - leftVol > 0
                                                ? maxVolume - leftVol
                                                : 0,
                                        tests: [test],
                                    });
                                }
                                leftVol -= maxVolume;
                            }
                        } else pass2.push(test);
                    });

                    pass2.forEach((test) => {
                        let placed = false;
                        flasks.forEach((c) => {
                            if (c.left >= test.required_volume && !placed) {
                                c.left -= test.required_volume;
                                c.tests.push(test);
                                placed = true;
                            }
                        });
                        if (!placed) {
                            flasks.push({
                                left: maxVolume - test.required_volume,
                                tests: [test],
                            });
                        }
                    });

                    containers.push({ ...cMap[kk], flasks });
                });

                res.push({
                    id: this.labMap[k].id,
                    name: this.labMap[k].name,
                    Settings: this.labMap[k].Settings,
                    tests: tMap[k],
                    containers,
                });
            });

            return res;
        },
        /** helps checkbox in Lab Selection and interacts with selectedLabs  */
        selectedLabs() {
            if (this.selectedLabIDs instanceof Array) {
                return this.labs.filter((v) => {
                    return this.selectedLabIDs.indexOf(v.id) >= 0;
                });
            }
            return [];
        },
        selectedTests() {
            if (this.selectedTestIDs instanceof Array) {
                return this.orderCodes.filter((v) => {
                    return this.selectedTestIDs.indexOf(v.id) >= 0;
                });
            }
            return [];
        },
        selectedTestsNoGroups() {
            return this.selectedTests.filter((v) => !v.isGroup);
        },
        //------------------------------------------------------
        validCPTs() {
            return this.mappedCPTs
                .filter((v) => v.valid)
                .map((v) => {
                    v.diagnosis = this.diagnosis;
                    v.groups = undefined;
                    v.codes = this.getTestIDs(v.tests);
                    v.tests = undefined;
                    return v;
                });
        },
    },
    mounted() {
        //this.getLabs();
    },
};
</script>