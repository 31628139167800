<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" lg="4" offset-lg="1" align="center">
                <v-row dense>
                    <v-col cols="12">
                        <dropdown
                            label="Ordering Facility"
                            model="Facility"
                            class="required"
                            v-model="facilityID"
                            :rules="[(val)=>!!val||'Required Field']"
                            :conditions="[{id:{in:$store.state.user.payload.ls}},{role:{eq:'Sender'}}]"
                            :trySet="facility_id || $store.state.user.payload.l"
                        ></dropdown>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <dropdown
                            label="Ordering Provider"
                            model="User"
                            class="required"
                            v-model="physicianID"
                            setWhenSingleResult
                            :rules="[(val)=>!!val||'Required Field']"
                            :conditions="[{facility_id:{eq:facilityID}},{is_provider:{eq:1}},{deleted:{is:null}}]"
                            :trySet="physician_id"
                            :disabled="!facilityID"
                        ></dropdown>
                        <!--<v-autocomplete
                            v-model="physicianID"
                            item-value="id"
                            item-text="name"
                            :items="uItems"
                            :loading="uLoading"
                            :search-input.sync="uSearch"
                            label="Physician"
                            class="required"
                            clearable
                            no-filter
                            hide-no-data
                            :disabled="!facilityID"
                        ></v-autocomplete>-->
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="2" class="hidden-md-and-down" align="center">
                <v-divider vertical></v-divider>
            </v-col>
            <v-col cols="12" lg="4">
                <v-row dense>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="patientID"
                            item-value="id"
                            item-text="name"
                            :items="pItems"
                            :loading="pLoading"
                            :search-input.sync="pSearch"
                            label="Search Patient"
                            clearable
                            no-filter
                            hide-no-data
                            hide-details
                            :disabled="!facilityID"
                        >
                            <template v-slot:item="{item}">
                                <v-list-item-avatar>
                                    <img
                                        v-if="item.image"
                                        :src="'https://lab-synapse.com/gql/storage/'+item.image"
                                    />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="item.dob">&nbsp;DOB:&nbsp;</span>
                                        <b>{{item.dob}}</b>
                                        <span v-if="item.mrn">&nbsp;MRN:&nbsp;</span>
                                        <b>{{item.mrn}}</b>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <small>
                            <span style="color:red">*</span> If you do not select an existing patient you will create a new patient's record on the next step!
                        </small>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="3" align-self="center">
                        <h4>
                            Billing Type
                            <span style="color:red">*</span>
                        </h4>
                    </v-col>
                    <v-col>
                        <v-radio-group v-model="btype" row mandatory>
                            <v-radio
                            class="pa-2"
                                :label="insType"
                                :value="insType"
                                v-for="insType in insTypes"
                                :key="insType"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <br />
        <br />
        <v-row justify="space-around">
            <v-col cols="4" align="center">
                <v-btn rounded v-on:click="step.down()" disabled>
                    <v-icon>mdi-arrow-left-bold</v-icon>BACK
                </v-btn>
            </v-col>
            <v-col cols="4" align="center">
                <!--<v-btn rounded v-on:click="step.save()">
                    <v-icon>mdi-content-save</v-icon>Save & Close
                </v-btn>-->
            </v-col>
            <v-col cols="4" align="center">
                <v-btn
                    rounded
                    v-on:click="step.up()"
                    :disabled="!stepAllowed"
                    :color="stepAllowed?'green':null"
                >
                    NEXT
                    <v-icon>mdi-arrow-right-bold</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
//import autocomplete from "../../components/AutoComplete";
import gql from "graphql-tag";
import dropdown from "../../components/DropDown.vue";
export default {
    data() {
        return {
            patientID: null,
            pItems: [],
            pLoading: null,
            pSearch: null,
            btype: null, //"Insurance",
            physicianID: null,
            uItems: [],
            uLoading: null,
            uSearch: null,
            facilityID: null,
            /** DO NOT CHANGE - linked to another modules */
            insTypes: ["Insurance", "Pre-Paid", "Self Pay", "Client Pay"],
        };
    },
    computed: {
        stepAllowed() {
            return this.btype && this.physicianID && this.facilityID;
        },
    },
    watch: {
        facilityID(n) {
            if(n!=this.facility_id) this.$emit("update:facility_id", n);
        },
        patientID: {
            handler(n) {
                if (n != this.patient_id) {
                    if (n == undefined) n = null;
                    this.$emit("update:patient_id", n);
                }
            },
            immediate: true,
        },
        physicianID: {
            handler(n) {
                if (n == undefined) n = null;
                this.$emit("update:physician_id", n);
            },
            immediate: true,
        },
        btype: {
            handler(n) {
                if (n == undefined) n = null;
                this.$emit("update:billing_type", n);
            },
            immediate: true,
        },
        patient_id(n) {
            if (n && this.patient_name) {
                this.pItems.push({ id: n, name: this.patient_name });
            }
            if (n != this.patientID) this.patientID = n;
        },
        patient_name(n) {
            if (n && this.patient_id) {
                this.pItems.push({ id: this.patient_id, name: n });
            }
        },
        /*physician_name(n) {
            if (n && this.physician_id)
                this.uItems.push({ id: this.physician_id, name: n });
        },
        physician_id(n) {
            if (n && this.physician_name)
                this.uItems.push({ id: n, name: this.physician_name });
            if (n != this.physicianID) this.physicianID = n;
        },*/
        billing_type(n) {
            if (n != this.btype) this.btype = n;
        },

        pSearch(n) {
            if (n) {
                let found = this.pItems.filter((v) => v.name == n);
                if (n.length > 1 && found.length <= 0) this.findPatient(n);
            }
        },
        uSearch(n) {
            if (n) {
                let found = this.uItems.filter((v) => v.name == n);
                if (n.length > 1 && found.length <= 0) this.findUser(n);
            }
        },
    },
    methods: {
        findPatient(search) {
            this.pLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                    PatientSearch(search:"${search}",facility_id:"${this.facilityID}",deleted:false,limit:20) {
                        id
                        name
                        dob
                        mrn
                        image
                    }
                }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.PatientSearch instanceof Array) {
                        this.pItems = res.data.PatientSearch;
                    }
                })
                .finally(() => {
                    this.pLoading = false;
                });
        },
        findUser(search) {
            this.uLoading = true;
            this.$apollo
                .query({
                    query: gql`{
                    Users(where:[{name:{like:"%${search}%"}},{deleted:{is:null}},{is_provider:{eq:1}},{facility_id:{eq:"${this.facilityID}"}} ], options:{page:1,itemsPerPage:50}) {
                        id
                        name
                    }
                }`,
                    fetchPolicy: "network-only",
                })
                .then((res) => {
                    if (res.data.Users instanceof Array) {
                        this.uItems = res.data.Users;
                    }
                })
                .finally(() => {
                    this.uLoading = false;
                });
        },
    },

    props: [
        "step",
        "patient_id",
        "physician_id",
        "physician_name",
        "billing_type",
        "patient_name",
        "facility_id",
    ],
    components: {
        dropdown,
    },
};
</script>